import React, {useState} from 'react';
import {AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, Box, keyframes} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CodeRounded from '@mui/icons-material/CodeRounded';

// Define keyframes for animations
const buttonHoverAnimation = keyframes`
    0% {
        transform: scale(1);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }
    50% {
        transform: scale(1.1);
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
    }
    100% {
        transform: scale(1);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }
`;

const appBarAnimation = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const NavBar = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static" sx={{
            backgroundColor: '#1e1e1e',
            boxShadow: 'none',
            animation: `${appBarAnimation} 0.5s ease-out`,
        }}>
            <Toolbar sx={{justifyContent: 'space-between', padding: '0 20px'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <CodeRounded sx={{
                        color: '#61dafb',
                        fontSize: '2rem',
                        mr: 1,
                        transition: 'transform 0.3s ease',
                        '&:hover': {transform: 'scale(1.1)'}
                    }}/>
                    <Typography
                        marginLeft={1.2}
                        variant="h6"
                        component="a"
                        href="#"
                        sx={{
                            color: 'white',
                            textDecoration: 'none',
                            fontWeight: 600,
                            transition: 'transform 0.3s ease, color 0.3s ease',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                color: '#61dafb',
                            },
                        }}
                    >
                        Stefan Markov | Experienced Software Developer
                    </Typography>
                </Box>
                <Box sx={{display: {xs: 'none', md: 'flex'}, gap: '20px'}}>
                    <Button href="#me" sx={navButtonStyle}>
                        About me
                    </Button>
                    <Button href="#education" sx={navButtonStyle}>
                        Education
                    </Button>
                    <Button href="#experience" sx={navButtonStyle}>
                        Experience
                    </Button>
                    <Button href="#hobbies" sx={navButtonStyle}>
                        Hobbies
                    </Button>
                    <Button href="#contact" sx={navButtonStyle}>
                        Contact
                    </Button>
                </Box>
                <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                    <IconButton color="inherit" onClick={handleMenuClick} sx={{
                        transition: 'transform 0.3s ease',
                        '&:hover': {
                            transform: 'scale(1.1)',
                            color: '#61dafb',
                        },
                    }}>
                        <MenuIcon/>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        PaperProps={{
                            style: {
                                backgroundColor: '#424242',
                                color: 'white',
                                transition: 'background-color 0.3s ease',
                                '&:hover': {
                                    backgroundColor: '#616161',
                                },
                            },
                        }}
                    >
                        <MenuItem component="a" href="#me" onClick={handleMenuClose}>
                            About me
                        </MenuItem>
                        <MenuItem component="a" href="#education" onClick={handleMenuClose}>
                            Education
                        </MenuItem>
                        <MenuItem component="a" href="#experience" onClick={handleMenuClose}>
                            Experience
                        </MenuItem>
                        <MenuItem component="a" href="#hobbies" onClick={handleMenuClose}>
                            Hobbies
                        </MenuItem>
                        <MenuItem component="a" href="#contact" onClick={handleMenuClose}>
                            Contact
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

// Enhanced button styles with animations
const navButtonStyle = {
    color: 'white',
    fontWeight: 'bold',
    transition: 'color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
        color: '#61dafb',
        textDecoration: 'underline',
        transform: 'scale(1.1)',
        boxShadow: '0px 8px 16px rgba(97, 218, 251, 0.5)',
        animation: `${buttonHoverAnimation} 1s ease-in-out`,
    },
};

export default NavBar;
