import React, {useState, useEffect} from 'react';
import {Box, Paper} from '@mui/material';
import {styled} from '@mui/system';
import WavesAnimation from './animations/WavesAnimation';
import AnimatedText from './AnimatedText';
import CodeIcon3 from "@mui/icons-material/Code";

const PictureContainer = styled(Box)(({theme}) => ({
    padding: '2rem',
    backgroundColor: '#1e1e1e',
    color: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    transition: 'background-color 0.5s ease, box-shadow 0.5s ease, transform 0.5s ease',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '400px',
    maxWidth: '100%',
    position: 'relative',
    overflow: 'hidden',
    flexDirection: 'column',
    '&:hover': {
        backgroundColor: '#292929',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
        transform: 'scale(1.00)',
    },
    '&:hover .hover-text': {
        opacity: 1,
        transform: 'translate(-50%, -5px)',
    },
}));

const WavesBackground = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
});

const ProfilePicture = styled('img')(({loaded, pixelated, theme}) => ({
    width: '380px',
    height: '400px',
    objectFit: 'cover',
    border: '5px solid #61dafb',
    borderRadius: '8px',
    opacity: loaded ? 1 : 0,
    filter: pixelated ? 'blur(8px) contrast(0.8)' : 'none', // Pixelation effect
    transition: 'opacity 1s ease-in-out, filter 0.7s ease-in-out',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: { // Targeting screens under 400px
        width: '80%', // Set width to 80% of the screen
    },
}));

const PixelOverlay = styled('div')(({loaded}) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.2) 1px, transparent 1px),
                      linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 1px, transparent 1px)`,
    backgroundSize: '20px 20px',
    opacity: loaded ? 0 : 1,
    transition: 'opacity 1s ease-in-out',
    zIndex: 2,
}));

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: '1rem',
    backgroundColor: '#1e1e1e',
    color: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
    marginTop: '1rem',
    width: 'fit-content',
    transition: 'background-color 0.5s ease, transform 0.5s ease',
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    zIndex: 3,
    '&:hover': {
        backgroundColor: '#292929',
        transform: 'scale(1.10)',
    },
}));

const HoverText = styled(Box)(({theme}) => ({
    position: 'absolute',
    bottom: '20%',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'rgba(30, 30, 30, 0.9)',
    color: '#61dafb',
    padding: '0.75rem 1.5rem',
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    zIndex: 4,
    opacity: 0,
    transition: 'opacity 0.5s ease, transform 0.3s ease',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem', // Optional: Adjust the gap between text and icon
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const AboutMePicture = () => {
    const images = ["/images/avatar.jpeg", "/images/pic.jpeg"];
    const [currentImage, setCurrentImage] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [pixelated, setPixelated] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setPixelated(true); // Start pixelation before fading out
            setLoaded(false);
            setTimeout(() => {
                setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
                setPixelated(false); // Remove pixelation as new image fades in
            }, 500); // Wait for fade-out before changing image
        }, 4000); // Change every 4 seconds

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [images.length]);

    const handleImageLoad = () => {
        setLoaded(true);
    };

    return (
        <PictureContainer>
            <WavesBackground>
                <WavesAnimation/>
            </WavesBackground>
            <ProfilePicture
                src={images[currentImage]}
                alt="Profile"
                onLoad={handleImageLoad}
                loaded={loaded}
                pixelated={pixelated}
            />
            <PixelOverlay loaded={loaded}/>
            <StyledPaper>
                <AnimatedText text="Stefan Markov | Experienced Software Developer"/>
            </StyledPaper>
            <HoverText className="hover-text">
                Java <CodeIcon3/> JavaScript <CodeIcon3/> TypeScript
            </HoverText>
        </PictureContainer>
    );
};

export default AboutMePicture;
