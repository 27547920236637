import React, {useState} from 'react';
import {Box, Typography, Button, Paper, Snackbar} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {styled} from '@mui/system';
import SnackbarContent from '@mui/material/SnackbarContent';

const EmailContainer = styled(Paper)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem 1rem',
    backgroundColor: '#1e1e1e',
    color: 'white',
    transition: 'background-color 0.3s ease',
    maxWidth: '100%',
    width: 'fit-content', // Set width to fit content
    '&:hover': {
        backgroundColor: '#292929',
    },
}));

const EmailText = styled(Typography)(({theme}) => ({
    fontWeight: 'bold',
    transition: 'color 0.3s ease',
    '&:hover': {
        color: '#61dafb',
    },
}));

const CopyEmail = () => {
    const [open, setOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const email = 'st.markov@icloud.com';

    const handleCopy = () => {
        if (email) {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(email)
                    .then(() => {
                        setCopied(true);
                        setOpen(true);
                        setTimeout(() => {
                            setCopied(false);
                        }, 4000);
                    })
                    .catch(err => console.error('Failed to copy: ', err));
            } else {
                const input = document.createElement('input');
                input.value = email;
                document.body.appendChild(input);
                input.select();
                document.execCommand('copy');
                document.body.removeChild(input);

                setCopied(true);
                setOpen(true);
                setTimeout(() => {
                    setCopied(false);
                }, 4000);
            }
        } else {
            console.warn('Email is not defined!');
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Box sx={{width: '100%', marginTop: '1rem'}}>
            <EmailContainer elevation={3}>
                <EmailText
                    sx={{fontSize: '1.1rem'}}
                    variant="body1">{email}</EmailText>
                <Button
                    variant="contained"
                    onClick={handleCopy}
                    sx={{marginLeft: '1rem', backgroundColor: '#61dafb', color: 'black'}}
                >
                    {copied ? <CheckIcon/> : <ContentCopyIcon/>}
                    {copied ? 'Copied!' : 'Copy'}
                </Button>
            </EmailContainer>
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                sx={{width: 'fit-content'}} // Set Snackbar width to fit content
            >
                <SnackbarContent
                    sx={{
                        backgroundColor: '#61dafb', // Set Snackbar background color to #61dafb
                        color: 'black', // Optional: Set text color to black for better contrast
                        display: 'flex', // Use flex to align items
                        alignItems: 'center',
                    }}
                    message={
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <CheckIcon sx={{marginRight: '0.5rem'}}/> {/* Add check icon */}
                            Email copied to clipboard!
                        </Box>
                    }
                />
            </Snackbar>
        </Box>
    );
};

export default CopyEmail;
