import React from 'react';
import {Box, Button, Typography, Tooltip} from '@mui/material';
import {styled} from '@mui/system';
import LinkIcon from '@mui/icons-material/Link';
import {SiSpringboot, SiJavascript, SiReact, SiMysql, SiSpring, SiHtml5, SiCss3, SiLeetcode} from 'react-icons/si';
import {FaDocker, FaJava, FaVuejs} from 'react-icons/fa';
import SchoolIcon from "@mui/icons-material/School";
import leetCodeBadge from '../assets/L23.png';
import leetCodeBadge2 from '../assets/L24.png';

const CertificatesContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '2rem',
    padding: '2rem',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
}));

const CertificateBox = styled(Box)(({theme}) => ({
    width: '250px',
    textAlign: 'center',
    margin: '1rem',
    borderRadius: '12px',
    padding: '1.5rem',
    backgroundColor: '#1f1f1f',
    color: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&:hover': {
        transform: 'scale(1.03)',
        border: '3px solid #61dafb',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
        backgroundColor: '#2a2a2a',
        borderColor: '#61dafb',
    },
}));

const CertificateButton = styled(Button)(({theme}) => ({
    color: 'white',
    backgroundColor: '#61dafb',
    marginTop: '1rem',
    '&:hover': {
        backgroundColor: '#21a1f1',
    },
}));

const StyledHeading = styled('h2')(({theme}) => ({
    textAlign: 'center',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: '#61dafb',
    margin: '1.5rem 0',
    textTransform: 'uppercase',
}));

const iconStyles = {
    fontSize: '3rem',
    color: '#61dafb',
    marginBottom: '0.5rem',
};

const IconContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    marginBottom: '0.5rem',
});

const certificates = [
    {
        title: 'Diploma Java Web Developer',
        url: 'https://softuni.bg/certificates/details/117958/5fc50a88',
        icons: [{icon: <SchoolIcon style={iconStyles}/>, tooltip: 'Diploma'}],
    },
    {
        title: 'Diploma Java Full-Stack Developer',
        url: 'https://softuni.bg/certificates/details/122302/0716e914',
        icons: [{icon: <SchoolIcon style={iconStyles}/>, tooltip: 'Diploma'}],
    },
    {
        title: 'Diploma JS Web Developer',
        url: 'https://softuni.bg/certificates/details/122847/1822f03c',
        icons: [{icon: <SchoolIcon style={iconStyles}/>, tooltip: 'Diploma'}],
    },
    {
        title: 'MySQL',
        url: 'https://softuni.bg/certificates/details/89683/f25993b2',
        icons: [{icon: <SiMysql style={iconStyles}/>, tooltip: 'MySQL'}],
    },
    {
        title: 'Spring Advanced',
        url: 'https://softuni.bg/certificates/details/104187/3ba4841a',
        icons: [{icon: <SiSpringboot style={iconStyles}/>, tooltip: 'Spring Boot'}],
    },
    {
        title: 'Spring Data',
        url: 'https://softuni.bg/certificates/details/92931/19608b8b',
        icons: [{icon: <SiSpring style={iconStyles}/>, tooltip: 'Spring Data'}],
    },
    {
        title: 'Spring Fundamentals',
        url: 'https://softuni.bg/certificates/details/99939/2fb44243',
        icons: [{icon: <SiSpringboot style={iconStyles}/>, tooltip: 'Spring Boot'}],
    },
    {
        title: 'Java Advanced',
        url: 'https://softuni.bg/certificates/details/83684/abeddc06',
        icons: [{icon: <FaJava style={iconStyles}/>, tooltip: 'Java'}],
    },
    {
        title: 'Java OOP',
        url: 'https://softuni.bg/certificates/details/87137/a8b8922f',
        icons: [{icon: <FaJava style={iconStyles}/>, tooltip: 'Java'}],
    },
    {
        title: 'Java Fundamentals',
        url: 'https://softuni.bg/certificates/details/79641/aafcea10',
        icons: [{icon: <FaJava style={iconStyles}/>, tooltip: 'Java'}],
    },
    {
        title: 'HTML & CSS',
        url: 'https://softuni.bg/certificates/details/101450/983a084a',
        icons: [
            {icon: <SiHtml5 style={iconStyles}/>, tooltip: 'HMTL'},
            {icon: <SiCss3 style={iconStyles}/>, tooltip: 'CSS'}
        ]
    },
    {
        title: 'JS Advanced',
        url: 'https://softuni.bg/certificates/details/108145/1e6b5688',
        icons: [{icon: <SiJavascript style={iconStyles}/>, tooltip: 'JavaScript'}],
    },
    {
        title: 'JS Applications',
        url: 'https://softuni.bg/certificates/details/110256/9bce4c3c',
        icons: [{icon: <SiJavascript style={iconStyles}/>, tooltip: 'JavaScript'}],
    },
    {
        title: 'ReactJS',
        url: 'https://softuni.bg/certificates/details/122131/8d4c35e6',
        icons: [{icon: <SiReact style={iconStyles}/>, tooltip: 'ReactJS'}],
    },
    {
        title: 'JS Back-End',
        url: 'https://softuni.bg/certificates/details/122222/7900f68a',
        icons: [{icon: <SiJavascript style={iconStyles}/>, tooltip: 'JavaScript'}],
    },
    {
        title: 'Vue - The Complete Guide',
        url: 'https://www.udemy.com/certificate/UC-f57ea539-9c88-4297-87a0-483b315fe669/',
        icons: [{icon: <FaVuejs style={iconStyles}/>, tooltip: 'Vue.js'}],
    },
    {
        title: 'Java Full-Stack with Spring Boot and React',
        url: 'https://www.udemy.com/certificate/UC-8597b55f-f84b-49d5-81a7-da011d225070/',
        icons: [
            {icon: <SiSpringboot style={iconStyles}/>, tooltip: 'Spring Boot'},
            {icon: <FaJava style={iconStyles}/>, tooltip: 'Java'},
            {icon: <SiReact style={iconStyles}/>, tooltip: 'React'},
        ],
    },
    {
        title: 'RESTful Web Services with Spring Boot',
        url: 'https://www.udemy.com/certificate/UC-f57ea539-9c88-4297-87a0-483b315fe669/',
        icons: [
            {icon: <SiSpringboot style={iconStyles}/>, tooltip: 'Spring Boot'},
            {icon: <FaJava style={iconStyles}/>, tooltip: 'Java'},
        ],
    },
    {
        title: 'Docker - DevOps for Java & Spring Boot',
        url: 'https://www.udemy.com/certificate/UC-24f6a961-dcd8-4655-b89e-3ea3678c7bad/',
        icons: [
            {icon: <FaDocker style={iconStyles}/>, tooltip: 'Docker'},
            {icon: <FaJava style={iconStyles}/>, tooltip: 'Java'},
            {icon: <SiSpringboot style={iconStyles}/>, tooltip: 'Spring Boot'},
        ],
    },
    {
        title: 'LeetCode Badge 2023',
        url: leetCodeBadge,
        icons: [
            {icon: <SiLeetcode style={iconStyles}/>, tooltip: 'LeetCode'},

        ],
    },
    {
        title: 'LeetCode Badge 2024',
        url: leetCodeBadge2,
        icons: [
            {icon: <SiLeetcode style={iconStyles}/>, tooltip: 'LeetCode'},

        ],
    },
];

const CertificatesLinks = () => {
    return (
        <div id="certificates">
            <Box sx={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}} display="flex" justifyContent="center">
                <StyledHeading>All Diplomas / Certificates</StyledHeading>
            </Box>
            <CertificatesContainer>
                {certificates.map((cert, index) => (
                    <CertificateBox key={index}>
                        <IconContainer>
                            {cert.icons.map((iconObj, idx) => (
                                <Tooltip title={iconObj.tooltip} key={idx} arrow>
                                    <span>{iconObj.icon}</span>
                                </Tooltip>
                            ))}
                        </IconContainer>
                        <Typography variant="h6"
                                    sx={{marginBottom: '0.75rem', fontWeight: '500', fontSize: '1.1rem', flexGrow: 1}}>
                            {cert.title}
                        </Typography>
                        <CertificateButton variant="contained" startIcon={<LinkIcon/>} href={cert.url} target="_blank">
                            View Diploma
                        </CertificateButton>
                    </CertificateBox>
                ))}
            </CertificatesContainer>
        </div>
    );
};

export default CertificatesLinks;
