import {Box, Button, Typography} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import React from "react";

const DiplomaBox = ({title, link, description, image}) => {
    return (
        <Box
            sx={{
                width: '250px',
                textAlign: 'center',
                margin: '1rem',
                fontSize: '2rem',
                borderRadius: '12px',
                padding: '1.5rem',
                backgroundColor: '#292929',
                color: 'white',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                position: 'relative',
                overflow: 'hidden',
                '&:hover': {
                    border: '2px solid #61dafb',
                },
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0,
                    transition: 'opacity 0.3s ease',
                    zIndex: 1,
                    borderRadius: 'inherit',
                },
                '&:hover::before': {
                    opacity: 1,
                },
            }}
        >
            {/* Content of DiplomaBox */}
            <Box sx={{position: 'relative', zIndex: 2}}>
                <Typography variant="body2" sx={{fontWeight: 'bolder', marginBottom: '0.5rem', color: 'white'}}>
                    {title}
                </Typography>
                {description && (
                    <Typography variant="body2" sx={{marginTop: '0.5rem'}}>
                        {description}
                    </Typography>
                )}
                <Button
                    variant="contained"
                    startIcon={<LinkIcon/>}
                    href={link}
                    target="_blank"
                    sx={{
                        color: 'white',
                        backgroundColor: '#61dafb',
                        '&:hover': {
                            backgroundColor: '#21a1f1', // Darker on hover
                        },
                    }}
                >
                    View Diploma
                </Button>
            </Box>
        </Box>
    );
};

export default DiplomaBox;
