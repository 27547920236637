import {Box, Button} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import React from "react";

const ShiningButton = () => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '1rem'}}>
            <Button
                variant="outlined"
                href="#certificates"
                sx={{
                    backgroundColor: '#61dafb',
                    color: 'white',
                    width: '100%',
                    fontWeight: 'bolder',
                    textAlign: 'center',
                    fontSize: '1.5rem',
                    borderColor: '#61dafb',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                        backgroundColor: '#21a1f1',
                        borderColor: '#21a1f1',
                    },
                    // Glowing border effect
                    '&:after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        borderRadius: 'inherit',
                        border: '2px solid rgba(255, 255, 255, 0.6)',
                        animation: 'glow 2.5s infinite alternate',
                        zIndex: 1,
                    },
                    '@keyframes glow': {
                        '0%': {
                            boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
                        },
                        '100%': {
                            boxShadow: '0 0 20px rgba(255, 255, 255, 1)',
                        },
                    },
                    '& .MuiButton-startIcon': {
                        marginRight: '1rem', // Space between icon and text
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
            >
                <SchoolIcon sx={{fontSize: '4rem', marginRight: '10px'}}/>
                View All Certificates
            </Button>
        </Box>
    );
};

export default ShiningButton;
