import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const WavesAnimation = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        if (!canvasRef.current) return;

        // Create a scene
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(40, canvasRef.current.clientWidth / canvasRef.current.clientHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, alpha: true });
        renderer.setSize(canvasRef.current.clientWidth, canvasRef.current.clientHeight);

        const geometry = new THREE.PlaneGeometry(10, 10, 32, 32);
        const material = new THREE.MeshStandardMaterial({ color: 0x61dafb, wireframe: true });
        const plane = new THREE.Mesh(geometry, material);
        scene.add(plane);

        // Lighting
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);

        camera.position.z = 5;

        // Animation loop
        const clock = new THREE.Clock();
        const animate = () => {
            requestAnimationFrame(animate);
            const time = clock.getElapsedTime();

            for (let i = 0; i < plane.geometry.attributes.position.count; i++) {
                const z = Math.sin(i * 0.5 + time) * 0.5; // Wave effect
                plane.geometry.attributes.position.setZ(i, z);
            }
            plane.geometry.attributes.position.needsUpdate = true; // Required for Three.js to update vertex positions
            renderer.render(scene, camera);
        };
        animate();

        // Cleanup
        return () => {
            renderer.dispose();
        };
    }, []);

    return (
        <canvas
            ref={canvasRef}
            style={{
                display: "flex",
                alignItems: 'center',
                top: 0,
                left: 0,
                minWidth: '100%',
                minHeight: '100%',
                zIndex: -1,
            }}
        />
    );
};

export default WavesAnimation;
