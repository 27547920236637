import React, {useEffect, useState} from 'react';
import {Box, Button, Paper, Snackbar} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'; // Import drag indicator icon
import SnackbarContent from '@mui/material/SnackbarContent';
import Draggable from 'react-draggable'; // Import Draggable
import {styled} from '@mui/system';

const EmailContainer = styled(Paper)({
    display: 'flex',
    flexDirection: 'column', // Stack items vertically
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem',
    backgroundColor: 'white',
    color: 'white',
    maxWidth: '200px', // Compact width
    width: 'fit-content',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
        backgroundColor: '#292929',
    },
});

const DragIndicator = styled('span')({
    display: 'flex',
    alignItems: 'center',
    marginTop: '4px', // Spacing between button and drag instruction
    fontSize: '0.8rem',
    color: '#555',
});

const StickyInfo = () => {
    const [open, setOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const email = 'st.markov@icloud.com';

    useEffect(() => {
        const handleResize = () => setIsVisible(window.innerWidth > 900);
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleCopy = (event) => {
        if (event.target.closest('.drag-indicator')) {
            // Prevent copy action if clicking on the drag indicator
            return;
        }

        if (email) {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(email)
                    .then(() => {
                        setCopied(true);
                        setOpen(true);
                        setTimeout(() => {
                            setCopied(false);
                        }, 4000);
                    })
                    .catch(err => console.error('Failed to copy: ', err));
            } else {
                const input = document.createElement('input');
                input.value = email;
                document.body.appendChild(input);
                input.select();
                document.execCommand('copy');
                document.body.removeChild(input);

                setCopied(true);
                setOpen(true);
                setTimeout(() => {
                    setCopied(false);
                }, 4000);
            }
        } else {
            console.warn('Email is not defined!');
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpen(false);
    };

    if (!isVisible) {
        return null
    }

    return (
        <>
            <Draggable>
                <Box sx={{position: 'fixed', bottom: '5%', right: '10px', cursor: 'move', zIndex: 9999}}>
                    <EmailContainer elevation={3} onClick={handleCopy}>
                        <Button
                            variant="contained"
                            sx={{backgroundColor: '#61dafb', color: 'black'}}
                        >
                            {copied ? <CheckIcon/> : <ContentCopyIcon/>}
                            {copied ? 'Copied!' : 'Copy email'}
                        </Button>
                        {/* Drag indicator */}
                        <DragIndicator className="drag-indicator">
                            <DragIndicatorIcon sx={{marginRight: '4px', fontSize: '1rem'}}/>
                            Moveable
                        </DragIndicator>
                    </EmailContainer>
                </Box>
            </Draggable>
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <SnackbarContent
                    sx={{
                        backgroundColor: '#61dafb',
                        color: 'black',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    message={
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <CheckIcon sx={{marginRight: '0.5rem'}}/> {/* Add check icon */}
                            Email copied to clipboard!
                        </Box>
                    }
                />
            </Snackbar>
        </>
    );
};

export default StickyInfo;
