import React, { useRef, useState, useEffect } from 'react';
import { Box, IconButton, Slider, Typography } from '@mui/material';
import { PlayArrow, Pause } from '@mui/icons-material';

const TinyAudioPlayer = ({ src }) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        const audio = audioRef.current;
        const setAudioData = () => {
            setDuration(audio.duration);
        };
        const handleAudioEnd = () => {
            setIsPlaying(false);
            setProgress(0);
            setCurrentTime(0);
        };
        audio.addEventListener('loadedmetadata', setAudioData);
        audio.addEventListener('ended', handleAudioEnd);
        return () => {
            audio.removeEventListener('loadedmetadata', setAudioData);
            audio.removeEventListener('ended', handleAudioEnd);
        };
    }, []);

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
        const currentTime = audioRef.current.currentTime;
        const duration = audioRef.current.duration;
        setCurrentTime(currentTime);
        setProgress((currentTime / duration) * 100);
    };

    const handleSliderChange = (event, value) => {
        audioRef.current.currentTime = (value / 100) * audioRef.current.duration;
        setProgress(value);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <Box
            sx={{
                width: '250px',
                height: '80px',
                borderRadius: '8px',
                boxShadow: 2,
                backgroundColor: '#1e1e1e',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0.5rem 1rem',
                margin: '1rem auto',
                transition: 'transform 0.2s ease',
                '&:hover': {
                    transform: 'scale(1.05)',
                },
            }}
        >
            <Box display="flex" alignItems="center" width="100%">
                <IconButton onClick={togglePlay} color="primary" size="small">
                    {isPlaying ? <Pause /> : <PlayArrow />}
                </IconButton>
                <audio
                    ref={audioRef}
                    src={src}
                    onTimeUpdate={handleTimeUpdate}
                    style={{ display: 'none' }}
                />
                <Slider
                    value={progress}
                    onChange={handleSliderChange}
                    aria-label="Audio Progress"
                    size="small"
                    sx={{
                        width: '70%',
                        marginLeft: '1rem',
                        color: 'primary.main',
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="space-between" width="100%" mt={1}>
                <Typography variant="body2">{formatTime(currentTime)}</Typography>
                <Typography variant="body2">{formatTime(duration)}</Typography>
            </Box>
        </Box>
    );
};

export default TinyAudioPlayer;
