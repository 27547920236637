import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Box} from '@mui/material';

const InstallPrompt = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isInstalled, setIsInstalled] = useState(false);
    const [open, setOpen] = useState(false); // State to control dialog open/close

    useEffect(() => {
        // Check if the app is already installed
        const checkIfInstalled = () => {
            const isStandalone = window.matchMedia('(display-mode: standalone)').matches
                || window.navigator.standalone;
            const isPwaInstalled = localStorage.getItem('pwaInstalled') === 'true';
            setIsInstalled(isStandalone || isPwaInstalled); // Update installation state
        };

        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        // Check installation status on component mount
        checkIfInstalled();

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        // Listen for appinstalled event
        window.addEventListener('appinstalled', () => {
            setIsInstalled(true); // Update installation state
            localStorage.setItem('pwaInstalled', 'true'); // Save installation status in local storage
            setDeferredPrompt(null); // Clear the deferred prompt
        });

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    setIsInstalled(true); // Mark as installed
                    localStorage.setItem('pwaInstalled', 'true'); // Update local storage
                }
                setDeferredPrompt(null); // Clear the prompt after installation
            });
        }
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Function to determine if the device is mobile
    const isMobile = window.innerWidth < 600; // Adjust this breakpoint as needed

    return (
        <Box
            id="download-app"
            sx={{
                minWidth: '100%',
                width: '100%', // Full width of the viewport
            }}
        >
            {!isInstalled && (
                <>
                    {isMobile ? (
                        <>
                            <Button
                                sx={{
                                    minWidth: '100%',
                                    width: '100%'
                                }}
                                variant="contained"
                                color="primary"
                                onClick={handleOpen} // Open dialog when clicked
                            >
                                Install App
                            </Button>

                            {/* Dialog for mobile devices */}
                            <Dialog open={open} onClose={handleClose}>
                                <DialogTitle>How to Install the App</DialogTitle>
                                <DialogContent>
                                    <h3>App can be installed for preview locally or offline</h3>
                                    <ul>
                                        <li><strong>On Chrome:</strong> Tap the Share button and then
                                            select "Add to Home Screen."
                                        </li>
                                        <li><strong>On Safari (iOS, MacOS):</strong> Tap the Share button and then
                                            select "Add to Home Screen."
                                        </li>
                                        <li><strong>On Firefox:</strong> Tap the three dots in the menu and choose "Add
                                            to Home Screen."
                                        </li>
                                        <li><strong>Microsoft Edge:</strong> Click the three-dot menu in the top right
                                            corner of the browser, select "Apps" and then choose "Install this site as
                                            an app."
                                        </li>
                                    </ul>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">Close</Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    ) : (
                        // Tooltip for larger screens
                        <Tooltip title={
                            <div>
                                <h3>App can be installed for preview locally or offline</h3>
                                <ul>
                                    <li><strong>On Chrome:</strong> In the top right corner click the "Install" icon or
                                        click the three dots and select "Cast, save, share" and "Install".
                                    </li>
                                    <li><strong>On Safari:</strong> Tap the Share button and then select "Add to Dock".
                                    </li>
                                    <li><strong>On Firefox:</strong> Tap the three dots in the menu and choose "Add to
                                        Home Screen".
                                    </li>
                                    <li><strong>Microsoft Edge:</strong> Click the three-dot menu in the top right
                                        corner of the browser, select "Apps" and then choose "Install this site as an
                                        app".
                                    </li>
                                </ul>
                            </div>
                        }>
                            <Button
                                sx={{
                                    minWidth: '100%',
                                    width: '100%'
                                }}
                                variant="contained"
                                color="primary"
                                onClick={handleInstallClick} // Only install when clicked
                            >
                                Install App
                            </Button>
                        </Tooltip>
                    )}
                </>
            )}
        </Box>
    );
};

export default InstallPrompt;
