import React from 'react';
import {Box, Paper, Typography, IconButton} from '@mui/material';
import {FaDatabase} from 'react-icons/fa';
import {ArrowLeft, ArrowRight} from '@mui/icons-material';
import GlowingBorderWithStyles from "./GlowingBorder";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {SiHibernate, SiRedis} from "react-icons/si";
import {BiLogoSpringBoot} from "react-icons/bi";

const themes = [
    {icon: <SiRedis/>, title: 'Redis & Caching', description: 'High-performance caching and data storage.'},
    {icon: <FaDatabase/>, title: 'Spring Data', description: 'Effective data storage and retrieval techniques.'},
    {icon: <SiHibernate/>, title: 'Hibernate', description: 'Fast and efficient ORM for Java applications.'},
    {
        icon: <BiLogoSpringBoot/>,
        title: 'Spring Boot Test',
        description: 'Comprehensive testing strategies for Spring Boot applications.',
    },
];


// Custom Arrow component
const CustomArrow = ({onClick, direction}) => (
    <IconButton
        onClick={onClick}
        sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            [direction === 'left' ? 'left' : 'right']: {xs: '-10px', sm: '-20px'},
            zIndex: 2,
            backgroundColor: '#61dafb',
            color: '#1e1e1e',
            '&:hover': {
                backgroundColor: '#4dabf7',
                color: '#ffffff',
            },
            '& svg': {fontSize: {xs: '1.8rem', sm: '2.5rem'}}
        }}
    >
        {direction === 'left' ? <ArrowLeft/> : <ArrowRight/>}
    </IconButton>
);

// Slider settings
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomArrow direction="right"/>,
    prevArrow: <CustomArrow direction="left"/>,
    customPaging: (i) => (
        <div
            style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: '#61dafb',
                display: 'inline-block',
                margin: '0 5px',
                cursor: 'pointer',
            }}
        />
    ),
    appendDots: (dots) => (
        <div
            style={{
                position: 'absolute',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '10px',
            }}
        >
            <ul style={{margin: '0', padding: '0', display: 'flex'}}> {dots} </ul>
        </div>
    ),
    responsive: [
        {breakpoint: 1280, settings: {slidesToShow: 2, slidesToScroll: 1, centerPadding: "20px"}},
        {breakpoint: 1024, settings: {slidesToShow: 2, slidesToScroll: 1, centerPadding: "15px"}},
        {breakpoint: 768, settings: {slidesToShow: 1, slidesToScroll: 1, centerPadding: "10px"}},
        {breakpoint: 600, settings: {slidesToShow: 1, slidesToScroll: 1, centerPadding: "5px"}}
    ],
    centerMode: true,
    centerPadding: "30px",
};

const PresentationThemes = () => {
    return (
        <>
            <Box
                sx={{
                    margin: '0px',
                    p: 4,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    textAlign: 'center',
                }}
            >
                <Typography variant="h3" component="h2" color="white" gutterBottom>
                    Themes I Present About
                </Typography>
                <Typography variant="body1" color="grey.300" mb={3}>
                    Here are some of the core topics I love to present about, covering essential skills and insights in
                    the tech world.
                </Typography>

                <Slider {...settings}>
                    {themes.map((theme, index) => (
                        <Paper
                            key={index}
                            elevation={4}
                            sx={{
                                p: 3,
                                m: 2,
                                backgroundColor: '#1e1e1e',
                                color: 'white',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                                transition: 'background-color 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    backgroundColor: '#292929',
                                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
                                },
                                border: '2px solid #61dafb',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                flexDirection: 'column',
                                textAlign: 'center',
                                height: {xs: 250, sm: 200},
                                width: {xs: '80%', sm: '60%', md: '30%'},
                                maxWidth: 250,
                                mx: 'auto',
                            }}
                        >
                            <Box fontSize={50} color="#61dafb" mb={2}>
                                {theme.icon}
                            </Box>
                            <Typography variant="h6" component="h3" gutterBottom>
                                {theme.title}
                            </Typography>
                            <Typography variant="body2" color="grey.300">
                                {theme.description}
                            </Typography>
                        </Paper>
                    ))}
                </Slider>
            </Box>
            <GlowingBorderWithStyles/>
        </>
    );
};

export default PresentationThemes;
