import React from 'react';
import {Link} from 'react-router-dom';
import {Box, Typography, Button} from '@mui/material';
import {keyframes} from '@mui/system';

// Keyframes for animations
const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const bounce = keyframes`
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
`;

const NotFound = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            textAlign="center"
            bgcolor="#1e1e1e"
            color="white"
            padding="2rem"
            sx={{animation: `${fadeIn} 1.5s ease-in-out`}} // Fade-in effect
        >
            {/* 404 Text with Bounce Effect */}
            <Typography
                variant="h1"
                sx={{
                    color: '#61dafb',
                    fontSize: '4rem',
                    animation: `${bounce} 2s ease-in-out infinite`, // Bounce animation
                }}
            >
                404
            </Typography>

            {/* Subtext */}
            <Typography variant="h4" style={{marginBottom: '1rem'}}>
                Oops! Page not found.
            </Typography>
            <Typography variant="body1" style={{marginBottom: '2rem'}}>
                The page you're looking for doesn't exist or has been moved.
            </Typography>

            {/* Back to Home Button with Hover Effect */}
            <Link to="/" style={{textDecoration: 'none'}}>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        backgroundColor: '#61dafb',
                        color: '#1e1e1e',
                        padding: '10px 20px',
                        fontWeight: 'bold',
                        transition: 'transform 0.3s, background-color 0.3s',
                        '&:hover': {
                            backgroundColor: '#4cc9f0',
                            transform: 'scale(1.1)', // Slight zoom-in effect on hover
                        }
                    }}
                >
                    Back to Home
                </Button>
            </Link>
        </Box>
    );
};

export default NotFound;
