import React, {useRef, useEffect} from 'react';
import * as THREE from 'three';

const AnimatedBackgroundStar = () => {
    const canvasRef = useRef(null);
    const cometCount = 10; // Number of comets
    const cometTrailLength = 10; // Length of the comet trail

    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({canvas: canvasRef.current, alpha: true});
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setClearColor(0x000000, 0); // Transparent background

        // Create star particles
        const starCount = 10000;
        const starsGeometry = new THREE.BufferGeometry();
        const positions = new Float32Array(starCount * 3);
        const colors = new Float32Array(starCount * 3);
        const sizes = new Float32Array(starCount);
        const starColor = new THREE.Color();

        const starTexture = new THREE.TextureLoader().load('https://threejs.org/examples/textures/sprites/circle.png');

        for (let i = 0; i < starCount; i++) {
            positions[i * 3] = (Math.random() - 0.5) * 200;
            positions[i * 3 + 1] = (Math.random() - 0.5) * 200;
            positions[i * 3 + 2] = (Math.random() - 0.5) * 200;

            const hue = Math.random() * 0.15 + 0.1;
            starColor.setHSL(hue, 0.9, 0.7 + Math.random() * 0.3);
            colors[i * 3] = starColor.r;
            colors[i * 3 + 1] = starColor.g;
            colors[i * 3 + 2] = starColor.b;

            sizes[i] = Math.random() * 1 + 0.2;
        }

        starsGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        starsGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
        starsGeometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));

        const starMaterial = new THREE.PointsMaterial({
            size: 0.5,
            map: starTexture,
            vertexColors: true,
            transparent: true,
            opacity: 0.8,
            blending: THREE.AdditiveBlending,
            depthWrite: false,
            sizeAttenuation: true,
        });

        const stars = new THREE.Points(starsGeometry, starMaterial);
        scene.add(stars);

        // Create comet trails
        const cometGeometry = new THREE.BufferGeometry();
        const cometPositions = new Float32Array(cometCount * cometTrailLength * 3);
        const cometColors = new Float32Array(cometCount * cometTrailLength * 3);
        const cometSizes = new Float32Array(cometCount * cometTrailLength);

        for (let i = 0; i < cometCount; i++) {
            // Initial random positions for comets
            const initialX = (Math.random() - 0.5) * 200;
            const initialY = (Math.random() - 0.5) * 200;
            const initialZ = Math.random() * 100; // Start with depth
            for (let j = 0; j < cometTrailLength; j++) {
                const index = (i * cometTrailLength + j) * 3;
                cometPositions[index] = initialX;
                cometPositions[index + 1] = initialY;
                cometPositions[index + 2] = initialZ;

                // Set color to a gradient resembling space
                const cometHue = Math.random() * 0.4 + 0.5; // A mix of blue and purple hues
                const cometColor = new THREE.Color().setHSL(cometHue, 0.8, 0.5);
                cometColors[index] = cometColor.r;
                cometColors[index + 1] = cometColor.g;
                cometColors[index + 2] = cometColor.b;

                // Set sizes (varying trail effect)
                cometSizes[i * cometTrailLength + j] = 0.5 - (j / cometTrailLength) * 0.3; // Decrease size for the tail
            }
        }

        cometGeometry.setAttribute('position', new THREE.BufferAttribute(cometPositions, 3));
        cometGeometry.setAttribute('color', new THREE.BufferAttribute(cometColors, 3));
        cometGeometry.setAttribute('size', new THREE.BufferAttribute(cometSizes, 1));

        const cometMaterial = new THREE.PointsMaterial({
            size: 1.0,
            map: starTexture, // Use the same circular texture for comets
            transparent: true,
            blending: THREE.AdditiveBlending,
            depthWrite: false,
            sizeAttenuation: true,
            alphaTest: 0.5,
            opacity: 0.7,
        });

        const comets = new THREE.Points(cometGeometry, cometMaterial);
        scene.add(comets);

        camera.position.z = 50;

        const animate = () => {
            requestAnimationFrame(animate);

            // Update comet positions
            for (let i = 0; i < cometCount; i++) {
                for (let j = 0; j < cometTrailLength; j++) {
                    const index = (i * cometTrailLength + j) * 3;
                    // Move comet forward
                    cometPositions[index + 2] -= 0.5; // Speed of the comet
                    // Wrap around when comet goes off screen
                    if (cometPositions[index + 2] < -50) {
                        cometPositions[index + 2] = 50; // Reset back to start
                        cometPositions[index] = (Math.random() - 0.5) * 200; // Randomize X
                        cometPositions[index + 1] = (Math.random() - 0.5) * 200; // Randomize Y
                    }
                }
            }
            cometGeometry.attributes.position.needsUpdate = true;

            // Update stars animation (existing logic)
            stars.rotation.x += 0.0003;
            stars.rotation.y += 0.0003;

            renderer.render(scene, camera);
        };

        animate();

        const handleResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            renderer.dispose();
        };
    }, []);

    return (
        <canvas ref={canvasRef}
                style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1}}/>
    );
};

export default AnimatedBackgroundStar;
