import React from 'react';
import {Box, Typography, Paper, Grid, Button} from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import GlowingBorderWithStyles from "./GlowingBorder";

const GitHubAccounts = () => {
    return (
        <>
            <GlowingBorderWithStyles/>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                }}
            >
                <Grid container sx={{maxWidth: '100%', width: '100%'}}>
                    <Grid item xs={12}>
                        <Paper
                            elevation={3}
                            sx={{
                                padding: '2rem',
                                width: 'auto',
                                borderRadius: '8px',
                                backgroundColor: '#1e1e1e',
                                color: 'white',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                                transition: 'background-color 0.5s ease, box-shadow 0.5s ease',
                                '&:hover': {
                                    backgroundColor: '#292929',
                                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
                                },
                            }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 'bold',
                                    marginBottom: '1rem',
                                    transition: 'color 0.4s ease, transform 0.4s ease',
                                    textAlign: 'center',
                                    display: 'inline-block',
                                    '&:hover': {
                                        color: '#61dafb',
                                        transform: 'scale(1.05)',
                                    },
                                }}
                            >
                                <GitHubIcon sx={{fontSize: 40, verticalAlign: 'middle', marginRight: '0.5rem'}}/>
                                My GitHub Accounts
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    textIndent: '1.5rem',
                                    marginBottom: '1rem',
                                    lineHeight: 1.6,
                                    transition: 'color 0.4s ease',
                                    '&:hover': {
                                        color: '#61dafb',
                                    },
                                }}
                            >
                                Here are links to my GitHub repositories, where I showcase a wide range of work,
                                including student tasks, personal projects, LeetCode solutions, HackerRank challenges,
                                bootcamp project, and more. Dive in to explore my projects, contributions, and the
                                journey of problem-solving and learning!
                            </Typography>
                            {/* Button Container */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    gap: '1rem',
                                }}
                            >
                                <Button
                                    variant="contained"
                                    startIcon={<GitHubIcon/>}
                                    href="https://github.com/Stefan-Markov?tab=repositories"
                                    target="_blank"
                                    sx={{
                                        backgroundColor: '#61dafb',
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#0ea1e2',
                                        },
                                    }}
                                >
                                    GitHub Account
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<GitHubIcon/>}
                                    href="https://github.com/Steve-Markus?tab=repositories"
                                    target="_blank"
                                    sx={{
                                        backgroundColor: '#61dafb',
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#0ea1e2',
                                        },
                                    }}
                                >
                                    GitHub Account
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <GlowingBorderWithStyles/>
        </>
    );
};

export default GitHubAccounts;
