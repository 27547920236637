import React from 'react';
import {Box, Typography, Paper, Grid} from '@mui/material';
import TechStackProject from './TechStackMicroservices';
import MicroservicesDescription from './MicroservicesDescription';
import MonolithTechStack from './TechStackMonolith';
import TinyAudioPlayer from "./TinyAudioPlayer";
import TechStackCourierApp from "./TechStackCourierApp";

const InfoComponent = () => {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                paddingTop: '2rem',
            }}
        >
            <Grid container sx={{maxWidth: 'auto', width: 'auto'}}>
                <Grid item xs={12}>
                    <Paper
                        elevation={3}
                        sx={{
                            padding: '2rem',
                            borderRadius: '8px',
                            backgroundColor: '#1e1e1e',
                            color: 'white',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                            transition: 'background-color 0.5s ease, box-shadow 0.5s ease',
                            '&:hover': {
                                backgroundColor: '#292929',
                                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
                            },
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 'bold',
                                marginBottom: '1rem',
                                transition: 'color 0.4s ease, transform 0.4s ease',
                                textAlign: 'center',
                                '&:hover': {
                                    color: '#61dafb',
                                    transform: 'scale(1.05)',
                                    zIndex: 1,
                                },
                            }}
                        >
                            More information about my professional experience
                        </Typography>

                        {/* Number 1 */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '1rem',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "'Bebas Neue', sans-serif", // Custom font
                                    fontSize: '5rem',
                                    color: '#61dafb',
                                    letterSpacing: '0.1rem',
                                    textShadow: '4px 4px 10px rgba(0, 0, 0, 0.5)',
                                    transition: 'transform 0.3s ease-in-out, color 0.3s ease-in-out',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        color: 'white',
                                        transform: 'rotate(10deg) scale(1.2)',
                                    },
                                }}
                            >
                                <strong>1.</strong>
                            </Typography>
                        </Box>
                        <Box>
                            <TinyAudioPlayer src="/music/project-one.mp3"/>
                        </Box>
                        <Typography
                            variant="body1"
                            sx={{
                                textIndent: '1.5rem',
                                marginBottom: '1rem',
                                lineHeight: 1.6,
                                transition: 'color 0.4s ease, transform 0.4s ease',
                                textAlign: 'left',
                                '&:hover': {
                                    color: '#61dafb',
                                    transform: 'scale(1.02)',
                                    zIndex: 1,
                                },
                            }}
                        >

                            In this key project I worked on, we developed a large-scale system for a healthcare company
                            in the USA, where some of the largest hospitals were our clients. We developed a
                            large-scale system using microservice
                            architecture to ensure scalability, flexibility, and maintainability. The back-end was built
                            with Java and Spring Boot, leveraging JPA for data persistence and MySQL as the primary
                            database. We also used Liquibase to manage database migrations efficiently. To enhance the
                            performance and responsiveness of the system, we implemented Redis for caching, which
                            reduced the load on the database and significantly improved response times. Additionally, we
                            utilized Redis pub-sub messaging for efficient communication between services, enabling
                            real-time updates and notifications.

                            The system integrated with Azure Service Bus to handle asynchronous communication between
                            microservices, ensuring reliable and scalable message distribution. The project utilized
                            Docker for containerization, which made development, testing,
                            and deployment smoother by ensuring consistency across environments. Testing was handled
                            with JUnit 5 for unit testing and H2 DB for lightweight, in-memory database testing,
                            providing a robust testing environment to ensure code quality. This project was a deep dive
                            into building distributed, resilient systems using modern tools and frameworks, successfully
                            meeting the high-performance and scalability requirements of a microservices-based
                            architecture.
                        </Typography>

                        <TechStackProject/>
                        <MicroservicesDescription/>

                        {/* Number 2 */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '2rem',
                                marginBottom: '1rem',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "'Bebas Neue', sans-serif", // Custom font
                                    fontSize: '5rem',
                                    color: '#61dafb',
                                    letterSpacing: '0.1rem',
                                    textShadow: '4px 4px 10px rgba(0, 0, 0, 0.5)',
                                    transition: 'transform 0.3s ease-in-out, color 0.3s ease-in-out',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        color: 'white',
                                        transform: 'rotate(10deg) scale(1.2)',
                                    },
                                }}
                            >
                                <strong>2.</strong>
                            </Typography>
                        </Box>
                        <Box>
                            <TinyAudioPlayer src="/music/project-two.mp3"/>
                        </Box>
                        <Typography
                            variant="body1"
                            sx={{
                                textIndent: '1.5rem',
                                marginBottom: '1rem',
                                lineHeight: 1.6,
                                transition: 'color 0.4s ease, transform 0.4s ease',
                                textAlign: 'left',
                                '&:hover': {
                                    color: '#61dafb',
                                    transform: 'scale(1.02)',
                                    zIndex: 1,
                                },
                            }}
                        >

                            Another key project I worked on was a comprehensive logistics management platform for a
                            company in the logistics and supply chain industry. The goal was to streamline operations
                            and improve route planning. On the back end, we utilized the Spring Framework along with
                            Spring Data for database interactions and Spring Security for authentication and
                            authorization. Hibernate facilitated ORM, while MySQL served as the database management
                            system.

                            Integrating third-party APIs was crucial for ensuring up-to-date delivery statuses,
                            enhancing the experience for both customers and operations staff. The platform included an
                            inventory management module, allowing the warehouse team to track stock levels, shipment
                            schedules, and item locations across storage facilities. Hibernate and MySQL worked together
                            to provide seamless data management and persistence.

                            For the Front-end, we chose Vue.js to build an interactive and dynamic user interface. This
                            resulted in a smooth, responsive experience, enabling logistics users to monitor shipments
                            through an intuitive dashboard.
                        </Typography>
                        <MonolithTechStack/>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '2rem',
                                marginBottom: '1rem',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "'Bebas Neue', sans-serif", // Custom font
                                    fontSize: '5rem',
                                    color: '#61dafb',
                                    letterSpacing: '0.1rem',
                                    textShadow: '4px 4px 10px rgba(0, 0, 0, 0.5)',
                                    transition: 'transform 0.3s ease-in-out, color 0.3s ease-in-out',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        color: 'white',
                                        transform: 'rotate(10deg) scale(1.2)',
                                    },
                                }}
                            >
                                <strong>3.</strong>
                            </Typography>
                        </Box>
                        <Box>
                            <TinyAudioPlayer src="/music/project-three.mp3"/>
                        </Box>
                        <Typography
                            variant="body1"
                            sx={{
                                textIndent: '1.5rem',
                                marginBottom: '1rem',
                                lineHeight: 1.6,
                                transition: 'color 0.4s ease, transform 0.4s ease',
                                textAlign: 'left',
                                '&:hover': {
                                    color: '#61dafb',
                                    transform: 'scale(1.02)',
                                    zIndex: 1,
                                },
                            }}
                        >
                            Developed a comprehensive courier delivery tracking application leveraging Java and Spring
                            Boot for the backend, with JPA for data persistence and MySQL as the primary database. The
                            frontend was built using Vue.js, enhanced with Vuetify for intuitive UI components. The
                            application supported payment processing from clients and integrated with an Android app to
                            print fiscal receipts and bills of lading. It was designed as a Progressive Web App (PWA) to
                            support offline functionality, utilizing PouchDB for local data storage. TypeScript was
                            employed to ensure type safety, improve maintainability, and minimize bugs throughout the
                            development process.
                        </Typography>
                        <TechStackCourierApp/>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default InfoComponent;
