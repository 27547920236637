import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import GlowingBorderWithStyles from "./GlowingBorder";
import ShiningButton from "./ShiningButton";
import DiplomaBox from "./DiplomaBox";

const Education = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine); // Initialize state based on the online status

    useEffect(() => {
        const updateOnlineStatus = () => setIsOnline(navigator.onLine);

        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        // Clean up the event listeners on component unmount
        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, []);

    return (
        <div style={{
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            paddingTop: '2rem',
        }}>
            <GlowingBorderWithStyles />
            <Paper id={'education'}
                   elevation={3}
                   sx={{
                       padding: '2rem',
                       backgroundColor: '#1e1e1e',
                       color: 'white',
                       width: 'auto',
                       boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                   }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: '1rem',
                        textAlign: 'center',
                    }}
                >
                    Education
                </Typography>

                <Box sx={{ marginBottom: '1rem', border: '2px solid #61dafb', borderRadius: '8px', padding: '1rem' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Software Engineering
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#61dafb' }}>
                        Software University, 2019 Sept - 2022 Jan
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '0.5rem' }}>
                        A comprehensive program focused on software development, algorithms, and data structures.
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '0.5rem' }}>
                        Here is a video about my successful journey and starting work as a software developer.
                    </Typography>

                    {/* Video Component */}
                    {isOnline ? (
                        <Box sx={{
                            position: 'relative',
                            width: '100%',
                            paddingBottom: '56.25%', // 16:9 Aspect Ratio
                            height: 0,
                            margin: '1.5rem 0',
                            borderRadius: '12px',
                            overflow: 'hidden',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
                        }}>
                            <iframe
                                width="100%"
                                height="100%"
                                src="https://www.youtube.com/embed/0iU0Y5JRs6M?mute=1&controls=1" // Removed autoplay
                                title="Successfully hired video from Software University"
                                frameBorder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    border: 'none', // Remove border
                                }}
                            ></iframe>
                        </Box>
                    ) : (
                        <Typography variant="body1" sx={{ color: 'red', textAlign: 'center' }}>
                            No internet connection. Please check your connection to view the video.
                        </Typography>
                    )}

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                            sx={{
                                width: '250px',
                                textAlign: 'center',
                                margin: '1rem',
                                borderRadius: '12px',
                                padding: '1.5rem',
                                backgroundColor: '#292929',
                                color: 'white',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    border: '2px solid #61dafb',
                                },
                            }}
                        >
                            <Typography variant="body2" sx={{ marginBottom: '0.5rem', color: 'white' }}>
                                {'Successfully hired video from Software University'}
                            </Typography>
                            <Button
                                variant="contained"
                                startIcon={<LinkIcon />}
                                href={'https://www.youtube.com/watch?v=0iU0Y5JRs6M&list=PLlcYRzEHmgNm8XXJV7nKWmhIBglcDQrnx&index=28'}
                                target="_blank"
                                sx={{
                                    color: 'white',
                                    backgroundColor: '#61dafb',
                                    '&:hover': {
                                        backgroundColor: '#21a1f1', // Darker on hover
                                    },
                                }}
                            >
                                View video
                            </Button>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                        <DiplomaBox
                            title="Java Full-Stack Developer"
                            link="https://softuni.bg/certificates/details/122302/0716e914"
                            image="/images/full-stack.png"
                        />
                        <DiplomaBox
                            title="Java Web Developer"
                            link="https://softuni.bg/certificates/details/117958/5fc50a88"
                            image="/images/java.png"
                        />
                        <DiplomaBox
                            title="JavaScript Web Developer"
                            link="https://softuni.bg/certificates/details/122847/1822f03c"
                            image="/images/js.png"
                        />
                    </Box>
                    <ShiningButton />
                </Box>

                <Box sx={{ marginBottom: '1rem', border: '2px solid #61dafb', borderRadius: '8px', padding: '1rem' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Business Administration
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#61dafb' }}>
                        International Business School, 2017 - 2019
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '0.5rem' }}>
                        A detailed curriculum focused on business principles, management strategies, and financial
                        analysis.
                    </Typography>
                </Box>

                <Box sx={{ border: '2px solid #61dafb', borderRadius: '8px', padding: '1rem' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Physiotherapy
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#61dafb' }}>
                        National Sport Academy, 2011 - 2014
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '0.5rem' }}>
                        A specialized program emphasizing rehabilitation techniques, anatomy, and patient care
                        practices.
                    </Typography>
                </Box>
            </Paper>
            <GlowingBorderWithStyles />
        </div>
    );
};

export default Education;
