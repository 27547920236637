import React, {useState, useEffect} from 'react';
import {Box, Button, Paper, TextField, IconButton, Snackbar} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {styled} from '@mui/system';
import Draggable from 'react-draggable';
import {DragIndicator} from "@mui/icons-material";

const NoteContainer = styled(Paper)({
    padding: '1rem',
    backgroundColor: 'rgba(30, 30, 30, 0.9)',
    color: '#61dafb',
    width: '150px',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
        backgroundColor: '#292929',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const StickyNotes = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [noteText, setNoteText] = useState('');
    const [saved, setSaved] = useState(false);
    const [showSavedText, setShowSavedText] = useState(false);
    const [isVisible, setIsVisible] = useState(window.innerWidth > 900);

    useEffect(() => {
        const handleResize = () => {
            setIsVisible(window.innerWidth > 900);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleExpand = () => {
        setIsExpanded((prev) => !prev);
    };

    const handleSave = () => {
        setSaved(true);
        setShowSavedText(true);

        setTimeout(() => {
            setShowSavedText(false);
            setSaved(false);
        }, 3000);
    };

    if (!isVisible) return null;

    return (
        <>
            <Draggable>
                <Box sx={{position: 'fixed', top: '10%', right: '10px', zIndex: 9999}}>
                    <NoteContainer elevation={3}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <IconButton onClick={toggleExpand} size="small" sx={{
                                color: 'white',
                                borderRadius: '50%',
                                border: '3px solid white'
                            }}>
                                {isExpanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                            </IconButton>
                            <Box sx={{fontWeight: 'bold'}}>Take a Note</Box>
                            <DragIndicator className="drag-indicator">
                                <DragIndicatorIcon sx={{marginRight: '4px', fontSize: '1rem'}}/>
                            </DragIndicator>
                        </Box>

                        {isExpanded && (
                            <Box mt={2} width="100%">
                                <TextField
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    maxRows={8}
                                    variant="outlined"
                                    value={noteText}
                                    onChange={(e) => setNoteText(e.target.value)}
                                    placeholder="Write your note here..."
                                    sx={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        color: '#61dafb',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#61dafb',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#61dafb',
                                            },
                                        },
                                    }}
                                    InputProps={{style: {color: '#61dafb'}}}
                                />
                                <Button
                                    onClick={handleSave}
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#61dafb',
                                        color: 'black',
                                        marginTop: '0.5rem',
                                        width: '100%',
                                    }}
                                    startIcon={<CheckIcon/>}
                                >
                                    {showSavedText ? 'Saved!' : 'Save'}
                                </Button>
                            </Box>
                        )}
                    </NoteContainer>
                </Box>
            </Draggable>

            {/* Snackbar to show saved confirmation */}
            <Snackbar
                open={saved}
                autoHideDuration={3000}
                onClose={() => setSaved(false)}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                message="Note saved!"
                ContentProps={{
                    sx: {
                        backgroundColor: '#61dafb',
                        color: 'black',
                    },
                }}
            />
        </>
    );
};

export default StickyNotes;
