import React from 'react';
import {Box, Typography, Paper, Grid} from '@mui/material';
import AudioPlayerExperience from "./AudioPlayerExperience";

const MyExperience = () => {
    return (
        <Box id={'experience'}
             sx={{
                 width: '100%',
                 display: 'flex',
                 justifyContent: 'center',
                 backgroundColor: 'rgba(0, 0, 0, 0.6)', // Slightly darkened background to ensure text visibility
                 paddingTop: '2rem',
             }}
        >
            <Grid container sx={{maxWidth: 'auto', width: 'auto'}}>
                <Grid item xs={12}>
                    <Paper
                        elevation={3}
                        sx={{
                            padding: '2rem',
                            borderRadius: '8px',
                            backgroundColor: '#1e1e1e',
                            color: 'white',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                            transition: 'background-color 0.5s ease, box-shadow 0.5s ease', // Smooth transition for background and shadow
                            '&:hover': {
                                backgroundColor: '#292929', // Slightly lighter background color on hover
                                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)', // Enhance shadow on hover
                            },
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 'bold',
                                borderBottom: '4px solid #61dafb',
                                marginBottom: '1rem',
                                transition: 'color 0.4s ease, transform 0.4s ease', // Smooth color and scaling transition
                                textAlign: 'center', // Center text alignment
                                display: 'inline-block', // Ensure inline behavior for transform
                                '&:hover': {
                                    color: '#61dafb', // Change color on hover
                                    transform: 'scale(1.05)', // Slightly scale up on hover
                                    zIndex: 1, // Ensure text is on top during scale
                                },
                            }}
                        >
                            Experience
                        </Typography>
                        <br/>
                        <AudioPlayerExperience/>
                        <Typography
                            variant="body1"
                            sx={{
                                textIndent: '1.5rem',
                                marginBottom: '1rem',
                                lineHeight: 1.6,
                                transition: 'color 0.4s ease, transform 0.4s ease', // Smooth color and scaling transition
                                textAlign: 'start', // Center text alignment
                                display: 'inline-block', // Ensure inline behavior for transform
                                '&:hover': {
                                    color: '#61dafb', // Change color on hover
                                    transform: 'scale(1.02)', // Slightly scale up on hover
                                    zIndex: 1, // Ensure text is on top during scale
                                },
                            }}
                        >
                            I have extensive experience as a Software Developer, with a strong focus on back-end
                            development, where I have designed and implemented scalable, high-performance solutions for
                            various systems. My expertise in Java, Spring Boot, MySQL, Hibernate and Redis has been
                            instrumental in building robust applications with seamless integration, efficient data
                            management, and secure, maintainable server-side logic. These core technologies have driven
                            the success of my back-end projects.

                            In addition to my back-end focus, I have also gained experience with Node.js, further
                            enhancing my ability to create efficient, dynamic systems. While back-end development
                            remains my specialty, I’ve also contributed to front-end projects using React and Vue.js,
                            giving me a well-rounded perspective on full-stack development. This breadth of experience
                            allows me to collaborate effectively across different areas of a project, ensuring cohesive
                            and smooth user experiences.

                            Below is a detailed overview of the technologies I’ve utilized throughout my development
                            journey.
                        </Typography>

                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MyExperience;
