import React, {useState, useEffect} from 'react';

const AnimatedText = ({text = ''}) => {
    const [displayedText, setDisplayedText] = useState([]);
    const [percentLoaded, setPercentLoaded] = useState(0);
    const [showPercent, setShowPercent] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false); // Track if the text is fully loaded
    const [reloadKey, setReloadKey] = useState(0); // Used to force re-run of the effect

    useEffect(() => {
        if (!text) return; // Exit if text is not provided or empty

        const randomizeLetter = () => {
            const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            return chars[Math.floor(Math.random() * chars.length)];
        };

        // Initialize with an array of spaces (same length as the original text)
        let randomizedText = Array.from({length: text.length}, () => ' ');
        setDisplayedText(randomizedText);
        setIsLoaded(false); // Reset the loaded state when the animation starts
        setShowPercent(true); // Show the percentage during loading

        let currentIndex = 0;
        let rounds = 0;

        const intervalId = setInterval(() => {
            if (currentIndex < text.length) {
                if (rounds < 6) {
                    // Randomize letters until rounds are complete
                    randomizedText[currentIndex] = randomizeLetter();
                    setDisplayedText([...randomizedText]);
                    rounds++;
                } else {
                    // Reveal the correct letter after 6 rounds of randomization
                    randomizedText[currentIndex] = text[currentIndex];
                    setDisplayedText([...randomizedText]);
                    rounds = 0; // Reset rounds for the next index
                    currentIndex++; // Move to the next letter

                    // Update the percent loaded
                    const percent = Math.floor(((currentIndex + 1) / text.length) * 100);
                    setPercentLoaded(percent <= 100 ? percent : 100);
                }
            } else {
                clearInterval(intervalId); // Stop once all letters are revealed
                setPercentLoaded(100); // Set to 100% when finished
                setIsLoaded(true); // Mark as fully loaded

                // Hide the percentage display after 1 second
                setTimeout(() => {
                    setShowPercent(false);
                }, 1000);
            }
        }, 40); // Time between letter changes

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [text, reloadKey]); // Add reloadKey to dependency array to trigger re-run

    // Function to reset the animation
    const resetAnimation = () => {
        setReloadKey(prevKey => prevKey + 1); // Trigger reload by changing the key
    };

    return (
        <div style={{position: 'relative', display: 'inline-block'}}>
            <span
                style={{
                    alignItems: 'start',
                    fontFamily: 'monospace', // Use a monospace font for consistent character width
                    display: 'inline-block',
                }}
            >
                {displayedText.map((char, index) => (
                    <span
                        key={index}
                        style={{
                            color: 'darkgoldenrod',
                            transition: 'color 0.2s ease-in-out',
                        }}
                    >
                        {char}
                    </span>
                ))}
            </span>

            {/* Conditionally render percentage and spinner only if showPercent is true */}
            {showPercent && (
                <div style={{marginTop: '10px'}}>
                    <div
                        style={{
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            bottom: '-1.5rem', // Adjust to position below the text
                            left: 0,
                            fontSize: '0.8rem',
                        }}
                    >
                        <small
                            style={{
                                color: '#61dafb', // Color for the percentage
                                fontSize: '0.8rem',
                                fontWeight: 'bold', // Bold for better visibility
                                padding: '2px 4px', // Some padding around the text
                                borderRadius: '3px', // Rounded corners
                                marginBottom: '10px',
                                marginRight: '8px', // Add some space between percentage and spinner
                                animation: 'fadeIn 0.3s ease-in-out', // Optional fade-in animation
                            }}
                        >
                            {percentLoaded}%
                        </small>

                        {/* Spinner */}
                        <div
                            style={{
                                width: '12px',
                                height: '12px',
                                fontSize: '0.8rem',
                                border: '2px solid #61dafb',
                                marginBottom: '10px',
                                borderTop: '2px solid transparent',
                                borderRadius: '50%',
                                animation: 'spin 1s linear infinite',
                            }}
                        ></div>
                    </div>
                </div>
            )}

            {/* Show reload pointer when the text is fully loaded */}
            {isLoaded && (
                <div
                    style={{
                        position: 'absolute',
                        top: '-1.5rem',
                        right: '-1rem',
                        cursor: 'pointer',
                        color: '#61dafb',
                        fontSize: '1.2rem',
                        userSelect: 'none',
                    }}
                    onClick={resetAnimation}
                >
                    &#x21bb; {/* Reload icon (↻) */}
                </div>
            )}
        </div>
    );
};

// CSS for spinner animation
const spinnerStyles = document.createElement('style');
spinnerStyles.innerHTML = `
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;
document.head.appendChild(spinnerStyles);

export default AnimatedText;
