import React from 'react';
import {AppBar, Toolbar, Typography, keyframes, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/system';
import '@fontsource/arizonia';
import '@fontsource/great-vibes';

// Animation for letter-by-letter typing effect
const typingAnimation = keyframes`
    0% {
        width: 0;
    }
    50% {
        width: 100%;
    }
    100% {
        width: 0;
    }
`;

// Blink effect for cursor
const cursorAnimation = keyframes`
    0% {
        border-right-color: rgba(255, 255, 255, 0.75);
    }
    50% {
        border-right-color: transparent;
    }
    100% {
        border-right-color: rgba(255, 255, 255, 0.75);
    }
`;

const appBarAnimation = keyframes`
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Footer = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile screens

    return (
        <AppBar
            position="static"
            sx={{
                width: '100%',
                backgroundColor: '#1e1e1e',
                boxShadow: 'none',
                animation: `${appBarAnimation} 0.5s ease-out`,
                padding: '1rem 0',
            }}
        >
            <Toolbar
                sx={{
                    justifyContent: 'center', // Center content on both mobile and desktop
                    alignItems: 'center', // Vertically align content
                    textAlign: 'center', // Center text alignment
                    flexDirection: 'column', // Stack the text components vertically
                }}
            >
                {/* Signature with letter-by-letter typing effect */}
                <Typography
                    variant="h6"
                    component="p"
                    sx={{
                        color: '#61dafb',
                        fontFamily: '"Great Vibes", cursive', // Apply Great Vibes font
                        fontSize: isMobile ? '2rem' : '3rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        borderRight: '2px solid rgba(255, 255, 255, 0.75)',
                        width: 'fit-content',
                        maxWidth: 'fit-content',
                        animation: `
                            ${typingAnimation} 7s steps(45) infinite, 
                            ${cursorAnimation} 0.75s step-end infinite`,
                        textAlign: 'center',
                        marginBottom: isMobile ? '1rem' : '0.5rem',
                    }}
                >
                    {isMobile ? 'That was all for me. All best. ' :
                        'That was all for me. All the best, Stefan Markov. '}
                </Typography>

                {/* Copyright text */}
                <Typography
                    variant="body2"
                    color="white"
                    sx={{
                        fontSize: '1rem', // Slightly increase font size
                        fontWeight: 'bold', // Make text bold
                        marginTop: isMobile ? '1rem' : 0,
                    }}
                >
                    &copy; 2024 Stefan Markov | All rights reserved.
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

export default Footer;
