import React, {useState, useEffect} from 'react';
import {Box} from '@mui/material';
import {styled} from '@mui/system';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import InfoIcon from '@mui/icons-material/Info';
import SchoolIcon from '@mui/icons-material/School';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {Downloading, EmojiEvents, Message} from "@mui/icons-material";
import CodeIcon3 from "@mui/icons-material/Code";

const NavList = styled('ul')({
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    '& li': {
        margin: '0.5rem 0',
        transition: 'all 0.3s ease',
        '&:hover': {
            transform: 'scale(1.05)',
        },
        display: 'flex',
        alignItems: 'center',
    },
});

const NavLink = styled('a')(({active}) => ({
    color: '#61dafb',
    textDecoration: 'none',
    fontWeight: active ? 'bold' : 'normal',
    paddingBottom: '5px',
    borderBottom: active ? '2px solid white' : '2px solid transparent',
    transition: 'color 0.3s ease, border-bottom 0.3s ease',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem', // Spacing between icon and label
    '&:hover': {
        color: '#21a1f1',
    },
}));

const StickyNavMenu = ({isVisible}) => {
    const [activeItem, setActiveItem] = useState(null);

    const handleItemClick = (item) => {
        setActiveItem(item);
    };

    useEffect(() => {
        // Reset the active item when the component is closed
        if (!isVisible) setActiveItem(null);
    }, [isVisible]);


    const checkIfInstalled = () => {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches
            || window.navigator.standalone;
        const isPwaInstalled = localStorage.getItem('pwaInstalled') === 'true';
        return isStandalone || isPwaInstalled;
    };

    const isInstalled = checkIfInstalled();

    const navItems = [
        { id: 'contact', label: 'Contact', icon: <ContactMailIcon /> },
        { id: 'contact-me', label: 'Write me', icon: <Message /> },
        { id: 'me', label: 'About Me', icon: <InfoIcon /> },
        { id: 'experience', label: 'Experience', icon: <CodeIcon3 /> },
        { id: 'education', label: 'Education', icon: <SchoolIcon /> },
        { id: 'hobbies', label: 'Hobbies', icon: <FavoriteIcon /> },
        { id: 'certificates', label: 'Certificates', icon: <EmojiEvents /> },
        ...(isInstalled ? [] : [{ id: 'download-app', label: 'Download App', icon: <Downloading /> }]),
    ];

    return (
        <Box mt={2} width="100%">
            <Box component="nav">
                <NavList>
                    {navItems.map((item) => (
                        <li key={item.id} onClick={() => handleItemClick(item.id)}>
                            <NavLink href={`#${item.id}`} active={activeItem === item.id}>
                                {item.icon}
                                {item.label}
                            </NavLink>
                        </li>
                    ))}
                </NavList>
            </Box>
        </Box>
    );
};

export default StickyNavMenu;
