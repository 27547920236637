import React from 'react';
import {Box} from '@mui/material';

const GlowingBorder = () => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '5px',
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: '5px solid #61dafb',
                    boxSizing: 'border-box',
                    background: 'linear-gradient(to right, #61dafb 0%, rgba(97, 218, 251, 0.4) 50%, #61dafb 100%)',
                    backgroundSize: '200% 100%',
                    animation: 'glowEffect 1s linear infinite, pulsate 1.5s ease-in-out infinite',
                    filter: 'blur(10px)', // Increased blur for a more pronounced glow effect
                    boxShadow: '0 0 30px #61dafb, 0 0 50px #61dafb, 0 0 80px rgba(97, 218, 251, 0.8)', // Additional glow layers
                },
            }}
        />
    );
};

// Add keyframes animations to simulate the glowing and pulsating effect
const styles = `
@keyframes glowEffect {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

@keyframes pulsate {
    0%, 100% {
        box-shadow: 0 0 30px #61dafb, 0 0 50px #61dafb, 0 0 80px rgba(97, 218, 251, 0.8);
    }
    50% {
        box-shadow: 0 0 40px #61dafb, 0 0 60px #61dafb, 0 0 100px rgba(97, 218, 251, 1);
    }
}
`;

export const GlowingBorderWithStyles = () => (
    <>
        <style>{styles}</style>
        <GlowingBorder/>
    </>
);

export default GlowingBorderWithStyles;
