import React, {useState, useEffect} from 'react';
import {Box, IconButton, Paper} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {styled} from '@mui/system';
import Draggable from 'react-draggable';
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {DragIndicator} from "@mui/icons-material";
import StickyNavMenu from "./StickyNavMenu";

const NavigatorContainer = styled(Paper)({
    padding: '1rem',
    backgroundColor: 'rgba(30, 30, 30, 0.9)',
    color: '#61dafb',
    width: '150px',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
        backgroundColor: '#292929',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const StickyNavigator = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isVisible, setIsVisible] = useState(window.innerWidth > 900);

    useEffect(() => {
        const handleResize = () => {
            setIsVisible(window.innerWidth > 900);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleExpand = () => {
        setIsExpanded((prev) => !prev);
    };

    if (!isVisible) return null;

    return (
        <Draggable>
            <Box sx={{position: 'fixed', top: '20%', right: '10px', zIndex: 9998}}>
                <NavigatorContainer elevation={3}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}>
                        <IconButton onClick={toggleExpand} size="small" sx={{color: 'white',
                            borderRadius: '50%',
                            border: '3px solid white'
                        }}>
                            {isExpanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                        </IconButton>
                        <Box sx={{fontWeight: 'bold'}}>Navigator</Box>
                        <DragIndicator className="drag-indicator">
                            <DragIndicatorIcon sx={{marginRight: '4px', fontSize: '1rem'}}/>
                        </DragIndicator>
                    </Box>

                    {isExpanded && (
                        <StickyNavMenu/>
                    )}
                </NavigatorContainer>
            </Box>
        </Draggable>
    );
};

export default StickyNavigator;
