import React from 'react';
import {Box, Typography, Paper, Grid, Button} from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PeopleIcon from '@mui/icons-material/People';

const YouTubeChannel = () => {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                paddingTop: '2rem',
                paddingBottom: '2rem',
            }}
        >
            <Grid container sx={{maxWidth: '100%', width: '100%'}}>
                <Grid item xs={12}>
                    <Paper
                        elevation={3}
                        sx={{
                            padding: '2rem',
                            borderRadius: '8px',
                            backgroundColor: '#1e1e1e',
                            color: 'white',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                            transition: 'background-color 0.5s ease, box-shadow 0.5s ease',
                            '&:hover': {
                                backgroundColor: '#292929',
                                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
                            },
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 'bold',
                                marginBottom: '1rem',
                                textAlign: 'center',
                                display: 'inline-block',
                                transition: 'color 0.4s ease, transform 0.4s ease',
                                '&:hover': {
                                    color: '#61dafb',
                                    transform: 'scale(1.05)',
                                },
                            }}
                        >
                            <YouTubeIcon sx={{fontSize: 40, verticalAlign: 'middle', marginRight: '0.5rem'}}/>
                            My YouTube Channel
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{
                                textIndent: '1.5rem',
                                marginBottom: '1rem',
                                lineHeight: 1.6,
                                textAlign: 'start',
                                transition: 'color 0.4s ease',
                                '&:hover': {
                                    color: '#61dafb',
                                },
                            }}
                        >
                            My goal when I started my YouTube channel was to share my LeetCode solutions.
                            After that continue with classic problems and some interesting algorithms.
                            Join a growing community of developers! The channel has now grown to over 860 followers,
                            where we share programming insights, discussions and build an inclusive and
                            supportive learning community.
                        </Typography>

                        {/* Button Container */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '2rem',
                            }}
                        >
                            <Button
                                variant="contained"
                                startIcon={<YouTubeIcon/>}
                                href="https://www.youtube.com/@steve-markus"
                                target="_blank"
                                sx={{
                                    backgroundColor: '#61dafb',
                                    color: 'black',
                                    marginRight: '1rem',
                                    transition: 'transform 0.3s ease, background-color 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#0ea1e2',
                                        transform: 'translateY(-4px)',
                                    },
                                    '&:active': {
                                        transform: 'translateY(2px)',
                                    }
                                }}
                            >
                                Visit Channel
                            </Button>

                            <Button
                                variant="contained"
                                startIcon={<PlayArrowIcon/>}
                                href="https://www.youtube.com/watch?v=C_tedQQP47M&t=14s"
                                target="_blank"
                                sx={{
                                    backgroundColor: '#61dafb',
                                    color: 'black',
                                    transition: 'transform 0.3s ease, background-color 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#0ea1e2',
                                        transform: 'translateY(-4px)',
                                    },
                                    '&:active': {
                                        transform: 'translateY(2px)',
                                    }
                                }}
                            >
                                Watch Now
                            </Button>
                        </Box>

                        {/* Video Stats Section */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                marginTop: '1rem',
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    marginBottom: '1rem',
                                    textAlign: 'center',
                                }}
                            >
                                Most Watched Video
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#61dafb',
                                    gap: '1.5rem',
                                }}
                            >
                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                    <VisibilityIcon sx={{marginRight: '0.5rem'}}/>
                                    <Typography variant="body1">2.6k Views</Typography>
                                </Box>
                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                    <ThumbUpIcon sx={{marginRight: '0.5rem'}}/>
                                    <Typography variant="body1">16 Likes</Typography>
                                </Box>
                            </Box>
                        </Box>

                        {/* Community Stats Section */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '2rem',
                                color: '#61dafb',
                            }}
                        >
                            <PeopleIcon sx={{fontSize: 40, marginRight: '0.5rem'}}/>
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: 'center',
                                }}
                            >
                                860+ Subscribers and Counting!
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default YouTubeChannel;
