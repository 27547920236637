import React from 'react';
import {Box, Typography, Paper, Grid} from '@mui/material';

const Hobbies = () => {
    return (
        <Box id={'hobbies'}
             sx={{
                 width: '100%',
                 display: 'flex',
                 justifyContent: 'center',
                 backgroundColor: 'rgba(0, 0, 0, 0.6)', // Slightly darkened background to ensure text visibility
                 paddingTop: '2rem',
             }}
        >
            <Grid container sx={{maxWidth: 'auto', width: 'auto'}}>
                <Grid item xs={12}>
                    <Paper
                        elevation={3}
                        sx={{
                            padding: '2rem',
                            borderRadius: '8px',
                            backgroundColor: '#1e1e1e',
                            color: 'white',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                            transition: 'background-color 0.5s ease, box-shadow 0.5s ease', // Smooth transition for background and shadow
                            '&:hover': {
                                backgroundColor: '#292929', // Slightly lighter background color on hover
                                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)', // Enhance shadow on hover
                            },
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 'bold',
                                marginBottom: '1rem',
                                transition: 'color 0.4s ease, transform 0.4s ease', // Smooth color and scaling transition
                                textAlign: 'center', // Center text alignment
                                display: 'inline-block', // Ensure inline behavior for transform
                                '&:hover': {
                                    color: '#61dafb', // Change color on hover
                                    transform: 'scale(1.05)', // Slightly scale up on hover
                                    zIndex: 1, // Ensure text is on top during scale
                                },
                            }}
                        >
                            Hobbies and spare time
                        </Typography>
                        <br/>
                        <Typography
                            variant="body1"
                            sx={{
                                textIndent: '1.5rem',
                                marginBottom: '1rem',
                                lineHeight: 1.6,
                                transition: 'color 0.4s ease, transform 0.4s ease', // Smooth color and scaling transition
                                textAlign: 'auto', // Center text alignment
                                display: 'inline-block', // Ensure inline behavior for transform
                                '&:hover': {
                                    color: '#61dafb', // Change color on hover
                                    transform: 'scale(1.02)', // Slightly scale up on hover
                                    zIndex: 1, // Ensure text is on top during scale
                                },
                            }}
                        >
                            In my spare time, I enjoy creating content for my YouTube channel, where I share insights
                            and tutorials on software development. I also enjoy reading books and exploring new
                            frameworks and technologies. I love staying up-to-date with the latest
                            advancements in the tech world and continuously expanding my knowledge.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Hobbies;
