import React, {useState} from 'react';
import {Box, Button} from '@mui/material';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {solarizedlight} from 'react-syntax-highlighter/dist/esm/styles/prism';
import GlowingBorderWithStyles from "./GlowingBorder";

const CodeSnippet = () => {
    const [isJavaScript, setIsJavaScript] = useState(true); // State to toggle between snippets

    // JavaScript snippet
    const jsCodeString = `const helloWorld = () => {
    console.log("Hello, World! I'm Stefan Markov.");
};`;

    // Java snippet
    const javaCodeString = `public class HelloWorld {
    public static void main(String[] args) {
        System.out.println("Hello, World! I'm Stefan Markov.");
    }
}`;

    return (
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.6)', paddingTop: '2rem'}}>
            <GlowingBorderWithStyles/>
            <Box
                sx={{
                    backgroundColor: '#1e1e1e',
                    width: 'auto',
                    padding: '2rem',
                    transition: 'background-color 0.5s, transform 0.5s',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                    '&:hover': {
                        backgroundColor: '#292929'
                    },
                }}
            >
                <Box sx={{display: 'flex', justifyContent: 'start', marginBottom: '1rem'}}>
                    <Button
                        variant={isJavaScript ? 'contained' : 'outlined'}
                        onClick={() => setIsJavaScript(true)}
                        sx={{marginRight: '0.5rem', color: '#61dafb'}}
                    >
                        JavaScript
                    </Button>
                    <Button
                        variant={!isJavaScript ? 'contained' : 'outlined'}
                        onClick={() => setIsJavaScript(false)}
                        sx={{color: '#61dafb'}}
                    >
                        Java
                    </Button>
                </Box>
                <SyntaxHighlighter language={isJavaScript ? "javascript" : "java"} style={solarizedlight}>
                    {isJavaScript ? jsCodeString : javaCodeString}
                </SyntaxHighlighter>
            </Box>
            <GlowingBorderWithStyles/>
        </div>
    );
};

export default CodeSnippet;
