import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, IconButton, Slider } from '@mui/material';
import { PlayArrow, Pause } from '@mui/icons-material';

const AudioPlayerExperience = () => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        const audio = audioRef.current;
        const setAudioData = () => {
            setDuration(audio.duration);
        };
        const handleAudioEnd = () => {
            setIsPlaying(false);
            setProgress(0);
            setCurrentTime(0);
        };
        audio.addEventListener('loadedmetadata', setAudioData);
        audio.addEventListener('ended', handleAudioEnd);
        return () => {
            audio.removeEventListener('loadedmetadata', setAudioData);
            audio.removeEventListener('ended', handleAudioEnd);
        };
    }, []);

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
        const currentTime = audioRef.current.currentTime;
        const duration = audioRef.current.duration;
        setCurrentTime(currentTime);
        setProgress((currentTime / duration) * 100);
    };

    const handleSliderChange = (event, value) => {
        audioRef.current.currentTime = (value / 100) * audioRef.current.duration;
        setProgress(value);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <Box
            sx={{
                padding: '2rem',
                borderRadius: '12px',
                boxShadow: 3,
                textAlign: 'center',
                maxWidth: '600px',
                margin: '2rem auto',
                transition: 'transform 0.3s ease',
                '&:hover': {
                    transform: 'scale(1.02)',
                },
            }}
        >
            <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#61dafb' }}>
                Experience Audio
            </Typography>
            <audio
                ref={audioRef}
                src="/music/experience.mp3"
                onTimeUpdate={handleTimeUpdate}
                style={{ display: 'none' }} // Hide native audio player
            />
            <Box display="flex" alignItems="center" gap={2}>
                <IconButton onClick={togglePlay} color="primary">
                    {isPlaying ? <Pause /> : <PlayArrow />}
                </IconButton>
                <Slider
                    value={progress}
                    onChange={handleSliderChange}
                    aria-label="Audio Progress"
                    sx={{ flex: 1 }}
                />
            </Box>
            <Box display="flex" justifyContent="space-between" mt={2}>
                <Typography variant="body2">{formatTime(currentTime)}</Typography>
                <Typography variant="body2">{formatTime(duration)}</Typography>
            </Box>
        </Box>
    );
};

export default AudioPlayerExperience;
