import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';

const ScrollProgressBar = () => {
    const [scrollPercentage, setScrollPercentage] = useState(0);

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
        const scrollPercent = (scrollTop / scrollHeight) * 100;
        setScrollPercentage(scrollPercent);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Box
            sx={{
                position: 'fixed',
                // top: '60px', // Assuming your navbar height is 60px
                left: 0,
                width: `${scrollPercentage}%`,
                height: '6px',
                backgroundColor: '#61dafb',
                zIndex: 11,
                transition: 'width 0.25s ease',
            }}
        />
    );
};

export default ScrollProgressBar;
