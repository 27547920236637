import React from "react";
import {Box, TextField, Typography} from "@mui/material";

const MessageInput = ({message, onChange}) => {
    const maxLength = 300;
    const handleInputChange = (e) => {
        if (e.target.value.length <= maxLength) {
            onChange(e);
        }
    };

    return (
        <Box sx={{position: "relative", marginBottom: "1rem"}}>
            <TextField
                fullWidth
                label="Your Message"
                name="message"
                multiline
                rows={4}
                value={message}
                onChange={handleInputChange}
                variant="outlined"
                margin="normal"
                InputLabelProps={{style: {color: "#61dafb"}}}
                sx={{
                    backgroundColor: "#292929",
                    borderRadius: "4px",
                    textarea: {color: "white"},
                }}
                required
            />
            <Typography
                variant="caption"
                sx={{
                    position: "absolute",
                    bottom: "-20px",
                    right: "0",
                    color: "#61dafb",
                }}
            >
                {maxLength - message.length} characters left
            </Typography>
        </Box>
    );
};

export default MessageInput;
