import React from 'react';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
} from '@mui/lab';
import {Typography, Paper, Box, useMediaQuery, useTheme, Fade} from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import WorkIcon from '@mui/icons-material/Work';
import BusinessIcon from '@mui/icons-material/Business';
import {styled} from '@mui/system';
import AnimatedBackgroundStar from "./animations/AnimatedBackgroundStar";

const TimelineContainer = styled(Box)(({theme}) => ({
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    padding: '2rem',
    [theme.breakpoints.down('sm')]: {
        padding: '1rem',
    },
}));

const CustomTimelineItem = styled(TimelineItem)(({theme}) => ({
    '&:hover': {
        transform: 'scale(1.02)',
        transition: 'transform 0.3s ease',
    },
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}));

const CustomTimelineContent = styled(TimelineContent)(({theme}) => ({
    flex: 1,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
        textAlign: 'left',
    },
}));

const YearLabel = styled(Typography)(({theme}) => ({
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: '#61dafb',
    transition: 'color 0.3s ease',
    '&:hover': {
        color: '#00bfff', // Slightly different hover color for effect
    },
}));

const AnimatedText = styled(Typography)(({theme}) => ({
    transition: 'color 0.3s ease, transform 0.3s ease',
    '&:hover': {
        color: '#61dafb',
        transform: 'scale(1.005)',
    },
}));

const ExperienceTree = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const educationData = [
        {
            year: '2022 Mart - present',
            title: "Position: Software Developer",
            description: 'Company: IT Prolet',
            titleIcon: <WorkIcon/>,
            descriptionIcon: <BusinessIcon/>
        },
        {
            year: '2022 Mart - 2024 Dec',
            title: 'Position: Back-End Software Developer',
            description: 'Company: SoftAvail',
            titleIcon: <WorkIcon/>,
            descriptionIcon: <BusinessIcon/>
        },
        {
            year: '2022 Jan - 2022 Mart',
            title: 'Position: Intern Software Developer',
            description: 'Company: Motion Software',
            titleIcon: <WorkIcon/>,
            descriptionIcon: <BusinessIcon/>
        },
    ];

    return (
        <TimelineContainer sx={{padding: '1rem'}}>
            <AnimatedBackgroundStar/> {/* Add the animated background here */}

            <Timeline position={isMobile ? 'right' : 'alternate'}
                      sx={{maxWidth: 'auto', width: '95%', textAlign: 'center'}}>
                {educationData.map((item, index) => (
                    <Fade in={true} timeout={500} key={index}>
                        <CustomTimelineItem>
                            <TimelineSeparator>
                                <TimelineDot sx={{
                                    backgroundColor: '#61dafb',
                                    transition: 'transform 0.3s ease',
                                    '&:hover': {
                                        transform: 'scale(1.1)',
                                    }
                                }}>
                                    <CodeIcon/>
                                </TimelineDot>
                                {index < educationData.length - 1 && (
                                    <TimelineConnector
                                        sx={{backgroundColor: '#61dafb', transition: 'background-color 0.3s ease'}}/>
                                )}
                            </TimelineSeparator>
                            <CustomTimelineContent>
                                <YearLabel>{item.year}</YearLabel>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        border: '3px solid #61dafb',
                                        padding: '1rem',
                                        textAlign: 'start',
                                        fontWeight: 'bolder',
                                        borderRadius: '8px',
                                        backgroundColor: '#1e1e1e',
                                        color: 'white',
                                        transition: 'box-shadow 0.3s ease, background-color 0.3s ease',
                                        '&:hover': {
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
                                            backgroundColor: '#292929', // Slightly darker background on hover
                                        },
                                    }}
                                >
                                    <Box sx={{display: 'flex', alignItems: 'center', marginBottom: '0.5rem'}}>
                                        {item.titleIcon}
                                        <AnimatedText sx={{fontWeight: 'bolder', marginLeft: '0.5rem'}}
                                                      variant="h6">{item.title}</AnimatedText>
                                    </Box>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        {item.descriptionIcon}
                                        <AnimatedText sx={{fontWeight: 'bolder', marginLeft: '0.5rem'}}
                                                      variant="h6">{item.description}</AnimatedText>
                                    </Box>
                                </Paper>
                            </CustomTimelineContent>
                        </CustomTimelineItem>
                    </Fade>
                ))}
            </Timeline>
        </TimelineContainer>
    );
};

export default ExperienceTree;
