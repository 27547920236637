import React, {useState} from "react";
import {Box, TextField, Button, Typography, Snackbar} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import MessageInput from "./MessageInput";

const ContactForm = () => {
    const [formData, setFormData] = useState({name: "", email: "", message: ""});
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const encode = (data) =>
        Object.keys(data)
            .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: encode({"form-name": "contact", ...formData}),
        })
            .then(() => {
                setSuccess(true);
                setFormData({name: "", email: "", message: ""});
            })
            .catch(() => setError(true));
    };

    return (
        <form id={"contact-me"} name="contact" onSubmit={handleSubmit} data-netlify="true">
            <Box
                id="contact-form"
                sx={{
                    width: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    paddingBottom: "2rem",
                }}
            >
                <Box
                    sx={{
                        maxWidth: 600,
                        margin: "0rem auto",
                        padding: "2rem",
                        borderRadius: 8,
                        background: "linear-gradient(135deg, rgba(0, 0, 0, 0.6), #1e1e1e)",
                        color: "white",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                    }}
                >
                    <input type="hidden" name="form-name" value="contact"/>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                            textAlign: "center",
                            color: "#61dafb",
                            fontWeight: "bold",
                        }}
                    >
                        Contact Me
                    </Typography>
                    <TextField
                        fullWidth
                        label="Your Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{style: {color: "#61dafb"}}}
                        sx={{
                            backgroundColor: "#292929",
                            borderRadius: "4px",
                            input: {color: "white"},
                        }}
                        required
                    />
                    <TextField
                        fullWidth
                        label="Your Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{style: {color: "#61dafb"}}}
                        sx={{
                            backgroundColor: "#292929",
                            borderRadius: "4px",
                            input: {color: "white"},
                        }}
                        required
                    />
                    <MessageInput
                        message={formData.message}
                        onChange={handleChange}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        startIcon={<SendIcon/>}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "2rem auto 0",
                            padding: "0.75rem 2rem",
                            backgroundColor: "#61dafb",
                            color: "#1e1e1e",
                            fontWeight: "bold",
                            borderRadius: "4px",
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "#52bce0",
                            },
                        }}
                    >
                        Send
                    </Button>
                    <Snackbar
                        open={success}
                        onClose={() => setSuccess(false)}
                        message="Submitted successfully!"
                        autoHideDuration={4000}
                        sx={{
                            "& .MuiSnackbarContent-root": {
                                marginBottom: "2rem",
                                backgroundColor: "#61dafb",
                                color: "#1e1e1e",
                            },
                        }}
                    />
                    <Snackbar
                        open={error}
                        onClose={() => setError(false)}
                        message="There was an error with submitting."
                        autoHideDuration={4000}
                        sx={{
                            "& .MuiSnackbarContent-root": {
                                marginBottom: "2rem",
                                backgroundColor: "red",
                                color: "white",
                            },
                        }}
                    />
                </Box>
            </Box>
        </form>
    );
};

export default ContactForm;
