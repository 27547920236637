import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Grid, Button } from '@mui/material';
import EmailCopyComponent from './EmailCopyComponent';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const Contact = () => {
    const fullText = `If you would like to get in touch, please don’t hesitate to reach out to me via email. I would be more than happy to discuss any inquiries you may have. I genuinely look forward to connecting with you and exploring the possibilities of collaboration.`;
    const [displayedText, setDisplayedText] = useState('');
    const [isVisible, setIsVisible] = useState(false); // State to control visibility

    const colorizeText = (text) => {
        return text.split('').map((char, index) => (
            <span key={index} style={{color: `hsl(${Math.random() * 360}, 100%, 50%)`}}>
            {char}
        </span>
        ));
    };
    colorizeText("");

    useEffect(() => {
        setIsVisible(true); // Set visibility to true on mount

        let index = 0;
        const intervalId = setInterval(() => {
            if (index <= fullText.length) {
                setDisplayedText(fullText.slice(0, index));
                index++;
            } else {
                clearInterval(intervalId);
            }
        }, 40);

        return () => clearInterval(intervalId);
    }, [fullText]);

    const scrollToForm = () => {
        const formElement = document.getElementById('contact-form');
        if (formElement) {
            formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <Box
            id="contact"
            sx={{
                width: '100%',
                minWidth: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                paddingTop: '2rem',
                flexDirection: { xs: 'column', sm: 'row' },
                opacity: isVisible ? 1 : 0, // Control opacity
                transition: 'opacity 1s ease-in', // Transition for fade-in
            }}
        >
            <Grid container sx={{ maxWidth: '100%', width: '100%' }}>
                <Grid item xs={12}>
                    <Paper
                        elevation={3}
                        sx={{
                            padding: '2rem',
                            borderRadius: '8px',
                            backgroundColor: '#1e1e1e',
                            color: 'white',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                            transition: 'background-color 0.5s ease, box-shadow 0.5s ease',
                            '&:hover': {
                                backgroundColor: '#292929',
                                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)',
                            },
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 'bold',
                                borderBottom: '4px solid #61dafb',
                                marginBottom: '1rem',
                                transition: 'color 0.4s ease, transform 0.4s ease',
                                textAlign: 'center',
                                display: 'inline-block',
                                '&:hover': {
                                    color: '#61dafb',
                                    transform: 'scale(1.05)',
                                    zIndex: 1,
                                },
                            }}
                        >
                            Contact
                        </Typography>
                        <br />
                        <Typography
                            variant="body1"
                            sx={{
                                textIndent: '1.5rem',
                                transition: 'color 0.4s ease, transform 0.4s ease',
                                textAlign: 'auto',
                                display: 'inline-block',
                                '&:hover': {
                                    color: '#61dafb',
                                    transform: 'scale(1.01)',
                                    zIndex: 1,
                                },
                            }}
                        >
                            {displayedText}
                        </Typography>

                        <EmailCopyComponent />

                        {/* Button to scroll to the contact form */}

                        <Button
                            onClick={scrollToForm}
                            variant="contained"
                            startIcon={<ArrowDownwardIcon />}
                            sx={{
                                marginTop: '1rem',
                                backgroundColor: '#61dafb',
                                color: '#1e1e1e',
                                fontWeight: 'bold',
                                padding: '0.75rem 2rem',
                                borderRadius: '8px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    backgroundColor: '#52bce0',
                                },
                            }}
                        >
                            Go to Contact Form
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Contact;
