import {createClient} from '@supabase/supabase-js';
import {v4 as uuidv4} from 'uuid';

const supabaseUrl = 'https://hiayrjtcbrxktjzqfraj.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhpYXlyanRjYnJ4a3RqenFmcmFqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzM4NDA2MzgsImV4cCI6MjA0OTQxNjYzOH0.bRUmkq7A320b5EshZLGDi5uY9PWGnZwQF1qbIHPlJ4g';
const supabase = createClient(supabaseUrl, supabaseKey);

let isThrottled = false;

export const sendData = async (type) => {
    if (isThrottled) return;
    isThrottled = true;

    setTimeout(async () => {
        try {
            let sessionId = localStorage.getItem("key");
            if (sessionId == null) {
                sessionId = uuidv4();
                localStorage.setItem("key", sessionId);
            }

            if (type === "d") {
                const {data, error: selectError} = await supabase
                    .from('count')
                    .select('*')
                    .eq('session_id', sessionId)
                    .eq('type', 'd')
                    .order('created_at', {ascending: false})
                    .limit(1);

                if (selectError) {
                    console.log(selectError);
                } else if (data.length > 0) {
                    const lastEntry = data[0];
                    const lastEntryTime = new Date(lastEntry.created_at).getTime();
                    const currentTime = new Date().getTime();

                    if (currentTime - lastEntryTime < 30000) {
                        const {error: updateError} = await supabase
                            .from('count')
                            .update({created_at: new Date().toISOString()})
                            .eq('id', lastEntry.id);

                        if (updateError) {
                            console.log(updateError);
                        }
                    } else {
                        await createNewEntry(sessionId, type);
                    }
                } else {
                    await createNewEntry(sessionId, type);
                }
            } else {
                await createNewEntry(sessionId, type);
            }
        } finally {
            isThrottled = false;
        }
    }, 3000); // 3-second delay
};

const createNewEntry = async (sessionId, type) => {
    const {error} = await supabase
        .from('count')
        .insert([{session_id: sessionId, type: type}]);
    if (error) {
        console.log(error);
    }
};
