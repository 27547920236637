import React from 'react';
import {Box, Typography, Paper, Grid, Link} from '@mui/material';
import AudioPlayer from "./AudioPlayer";

const AboutMe = () => {
    return (
        <Box id={'me'}
             sx={{
                 width: '100%',
                 height: '100%',
                 display: 'flex',
                 justifyContent: 'center',
                 backgroundColor: 'rgba(0, 0, 0, 0.6)',
                 paddingTop: '2rem',
                 flexDirection: {xs: 'column', sm: 'row'}, // Stack on mobile
             }}
        >
            <Grid container sx={{maxWidth: 'auto', width: 'auto'}}>
                <Grid item xs={12}>
                    <Paper
                        elevation={3}
                        sx={{
                            padding: '2rem',
                            borderRadius: '8px',
                            backgroundColor: '#1e1e1e',
                            color: 'white',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                            transition: 'background-color 0.5s ease, box-shadow 0.5s ease', // Smooth transition for background and shadow
                            '&:hover': {
                                backgroundColor: '#292929', // Slightly lighter background color on hover
                                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.5)', // Enhance shadow on hover
                            },
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 'bold',
                                borderBottom: '4px solid #61dafb',
                                marginBottom: '1rem',
                                transition: 'color 0.4s ease, transform 0.4s ease', // Smooth color and scaling transition
                                textAlign: 'center', // Center text alignment
                                alignItems: 'center',
                                display: 'inline-block', // Ensure inline behavior for transform
                                '&:hover': {
                                    color: '#61dafb', // Change color on hover
                                    transform: 'scale(1.05)', // Slightly scale up on hover
                                    zIndex: 1, // Ensure text is on top during scale
                                },
                            }}
                        >
                            About Me
                        </Typography>
                        <br/>
                        <AudioPlayer/>
                        <Typography
                            variant="body1"
                            sx={{
                                marginBottom: '1rem',
                                lineHeight: 1.6,
                                transition: 'color 0.4s ease, transform 0.4s ease', // Smooth color and scaling transition
                                textAlign: 'auto', // Center text alignment
                                display: 'inline-block', // Ensure inline behavior for transform
                                '&:hover': {
                                    color: '#61dafb', // Change color on hover
                                    transform: 'scale(1.01)', // Slightly scale up on hover
                                    zIndex: 1, // Ensure text is on top during scale
                                },
                            }}
                        >
                            Hello! I'm Stefan Markov. I living in Sofia, Bulgaria.
                        </Typography>
                        <br/>
                        <Typography
                            variant="body1"
                            sx={{
                                textIndent: '1.5rem',
                                marginBottom: '1rem',
                                lineHeight: 1.6,
                                transition: 'color 0.4s ease, transform 0.4s ease', // Smooth color and scaling transition
                                textAlign: 'auto', // Center text alignment
                                display: 'inline-block', // Ensure inline behavior for transform
                                '&:hover': {
                                    color: '#61dafb', // Change color on hover
                                    transform: 'scale(1.01)', // Slightly scale up on hover
                                    zIndex: 1, // Ensure text is on top during scale
                                },
                            }}
                        >
                            I am a software developer with over 3 years of experience in building and optimizing modern,
                            scalable applications using both microservice and monolithic architectures. My expertise
                            includes Java, Spring Boot, MySQL, Hibernate, JavaScript, React, and Vue.js. I have a
                            passion for delivering high-performance, user-centered solutions—whether it's designing
                            sleek user interfaces or developing robust backend systems.
                            Throughout my career, I’ve worked with cross-functional teams, leveraging Agile
                            methodologies to create impactful software experiences that drive business growth. My
                            experience in international teams has strengthened my ability to collaborate effectively
                            across borders. I am always keen to explore new technologies and enhance my skill set to
                            stay ahead in the ever-evolving tech industry.
                        </Typography>
                        <Box
                            sx={{
                                transition: 'color 0.4s ease, transform 0.4s ease', // Smooth transition for the entire paragraph
                                '&:hover': {
                                    color: '#61dafb', // Turn entire paragraph text to #61dafb on hover
                                },
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    textIndent: '1.5rem',
                                    marginBottom: '1rem',
                                    lineHeight: 1.6,
                                    textAlign: 'auto',
                                    display: 'inline-block',
                                    transition: 'color 0.4s ease, transform 0.4s ease', // Ensure smooth transitions
                                    '&:hover': {
                                        transform: 'scale(1.01)', // Slight scale up on hover
                                    },
                                }}
                            >
                                Outside of work, I am deeply committed to continuous learning, constantly exploring new
                                technologies and staying current with industry trends to refine my expertise. I actively
                                contribute to the tech community through my&nbsp;
                                <Link
                                    href="https://www.youtube.com/@steve-markus"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        color: '#FF5722', // Bright color for visibility when not hovered
                                        fontWeight: 'bold',
                                        textDecoration: 'underline',
                                        textUnderlineOffset: '4px',
                                        transition: 'color 0.4s ease, transform 0.4s ease, box-shadow 0.4s ease',
                                        '&:hover': {
                                            color: '#61dafb', // Primary color on hover for the link
                                            textShadow: '0 0 8px rgba(97, 218, 251, 0.8)', // Add a glowing text shadow
                                            transform: 'scale(1.1)', // Bigger scale on hover for the link
                                            boxShadow: '0px 4px 10px rgba(97, 218, 251, 0.3)', // Slight shadow around the link
                                        },
                                    }}
                                >
                                    YouTube channel
                                </Link>, where I share insights,
                                tutorials, and best practices to support fellow developers in their growth.

                                I’d love to connect or collaborate — feel free to reach out!
                            </Typography>
                        </Box>

                        <Typography
                            variant="body1"
                            sx={{
                                transition: 'color 0.4s ease, transform 0.4s ease', // Smooth color and scaling transition
                                textAlign: 'auto', // Center text alignment
                                display: 'inline-block', // Ensure inline behavior for transform
                                '&:hover': {
                                    color: '#61dafb', // Change color on hover
                                    transform: 'scale(1.01)', // Slightly scale up on hover
                                    zIndex: 1, // Ensure text is on top during scale
                                },
                            }}
                        >
                            Feel free to reach out if you'd like to connect or collaborate!
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AboutMe;
